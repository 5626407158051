import { Point2D } from '@ahha/stableComponents/Canvas/types/point'
import { InternalShapeState, StatusPredicate } from '@ahha/stableComponents/Canvas/types/shape'
import { DynamicPreset, DynamicTheme } from '@ahha/stableComponents/Canvas/types/theme'
import { isFunction } from '@ahha/utils/@types/typeChecks'

const COMPUTATIONAL_ERROR = 0.001

export const isEqual = (a: number, b: number) => Math.abs(a - b) < COMPUTATIONAL_ERROR

export const isNotEqual = (a: number, b: number) => Math.abs(a - b) >= COMPUTATIONAL_ERROR

export const isApproximate = (a: number, b: number, tolerance: number) => Math.abs(a - b) <= tolerance

export const isSamePoint = (p1: Point2D, p2: Point2D) => isEqual(p1[0], p2[0]) && isEqual(p1[1], p2[1])

export const isBounded = (v: number, min: number, max: number) => v >= min && v <= max

/* Shape 공통 유틸 함수 */
export const getEditStatus = (predicate: boolean | StatusPredicate | undefined, status: InternalShapeState) => (
  isFunction(predicate)
    ? predicate(status)
    : predicate
)

/** dynamic theme, static theme 타입에 따라 도형 컴포넌트에서 사용가능한 theme 객체 반환 */
export const resolveThemeConfig = <T>(theme: T | DynamicTheme<T>, status: InternalShapeState) => {
  if (isFunction(theme)) {
    return theme(status)
  }
  return theme
}

/**
 * focused, clustered, hovered 상태에 따라 달라지는 theme preset function에
 * 도형 데이터와 전역 스타일 컨텍스트를 바인딩해 dynamic theme을 반환
 * */
export const getDynamicThemeConfig = <T, R = void, C = void>(
  preset: DynamicPreset<T, R, C>,
  data?: R,
  context?: C
) => (state: InternalShapeState) => {
  /* FIXME:ksh: context를 전달하지 않았을 때(undefined) preset 메서드 타입에 맞지 않는 문제 - 2024.02.22 */
    const { isFocused, isHovered, isClustered } = state
    const c = context as C

    if (!data) {
      const p = preset as DynamicPreset<T>

      if (isFocused && !isClustered) {
        return p.focused()
      }
      if (isHovered) {
        return p.hovered()
      }
      return p.default()
    }

    if (isFocused && !isClustered) {
      return preset.focused(data, c)
    }
    if (isHovered) {
      return preset.hovered(data, c)
    }
    // TODO: 다른 사람이 선택?
    return preset.default(data, c)
  }
