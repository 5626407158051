import { ThemeConfig } from '@ahha/theme/CampTheme'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ConfigType = {
  currentLanguage: string,
  currentTheme: string,
}

const initialState = {
  currentLanguage: 'ko',
  currentTheme: '',
}

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCurrentLanguage(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentLanguage: action.payload,
      }
    },
    setCurrentTheme(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentTheme: action.payload,
      }
    },
  },
})

export const { setCurrentLanguage, setCurrentTheme } = config.actions
export default config.reducer
