import BaseApi from '@/api/Base/BaseApi'
import {
  CreateImageMatchingNodePayload,
  GenerateMatchingPairsPayload,
  GenerateMatchingPairsResponse,
  GetImageMatchingNodeItemsPayload,
  GetImageMatchingNodeItemsResponse,
  ImageMatchingNodeInfo,
  UpdateImageMatchingNodePayload
} from '@/api/ImageMatchingNode/types'

const ROOT = '/image-matching'

export default class ImageMatchingNodeApi extends BaseApi {
  createImageMatchingNode(payload: CreateImageMatchingNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ImageMatchingNodeInfo>(`/project/${projectId}/image-matching/create`, rest)
  }

  updateImageMatchingNode(payload: UpdateImageMatchingNodePayload) {
    const { imageMatchingNodeId, ...rest } = payload
    return this.agent.put<ImageMatchingNodeInfo>(`${ROOT}/${imageMatchingNodeId}`, rest)
  }

  deleteImageMatchingNode(imageMatchingNodeId: string) {
    return this.agent.delete(`${ROOT}/${imageMatchingNodeId}`)
  }

  getImageMatchingNodeDetail(imageMatchingNodeId: string) {
    return this.agent.get<ImageMatchingNodeInfo>(`${ROOT}/${imageMatchingNodeId}`)
  }

  getImageMatchingNodeItems(payload: GetImageMatchingNodeItemsPayload) {
    const { imageMatchingNodeId,
      page,
      itemsPerPage,
      sortKey,
      sortDir,
      datasetNodeIds,
      search,
    } = payload
    return this.agent.get<GetImageMatchingNodeItemsResponse>(`${ROOT}/${imageMatchingNodeId}/list`, {
      params: {
        page,
        num_per_page: itemsPerPage ?? 100,
        sort_key: sortKey,
        sort_dir: sortDir,
        datasetNodeIds,
        search,
      },
    })
  }

  generateMatchingPairs(payload: GenerateMatchingPairsPayload) {
    const { imageMatchingNodeId, ...rest } = payload
    return this.agent.post<GenerateMatchingPairsResponse>(`${ROOT}/${imageMatchingNodeId}/auto-matching`, rest)
  }
}
