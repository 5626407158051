import { useCallback, useRef } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import { useStage } from '@ahha/stableComponents/Canvas/utils/Context/Stage/useStage'
import { clampMinMax } from '@ahha/utils/number'
import { shouldSuppressKeyEvent } from '@ahha/utils/event'
import { useGlobalEvent } from '@ahha/utils/hooks'
import { useCanvasViewport } from '@ahha/stableComponents/Canvas/utils/Context/Viewport/useCanvasViewport'

type TranslationEvent = DragEvent | MouseEvent

interface StageTranslationParams {
  when: (e: KonvaEventObject<TranslationEvent>, key: string) => boolean
  /** translate 동작을 제외할 상황. `when(e, k) === true`이고 `except(stage) === false`일 때 Stage 이동이 됨. */
  except?: (stage: Konva.Stage) => boolean
  captureKeyEvents?: boolean
}

interface BoundCoordinates {
  minX: number
  maxX: number
  minY: number
  maxY: number
}

interface Position {
  x: number
  y: number
}

const SMOOTH_TRANSLATION_FACTOR = 0.004
const TRANSLATION_PADDING = 20

/** TODO:ksh: when, except 컴포넌트에서 핸들링하지 말고 Stage에서 하나만 설정할 수 있도록 수정 - 2024.04.24 */
export const useStageTranslation = ({
  when,
  except,
  captureKeyEvents = false,
}: StageTranslationParams) => {
  /** TODO:ksh: store에 isTranslating 추가 - 2024.05.02 */
  const [stage, utils] = useStage((s) => s.node)

  const keyRecord = useRef('')
  const stageStart = useRef({ x: 0, y: 0 })
  const shapeStart = useRef({ x: 0, y: 0 })
  const cursorPoint = useRef({ x: 0, y: 0 })

  const getBoundMinMax = useCallback((stage: Konva.Stage) => {
    const isSandboxed = utils.isSandbox()
    const { gutter } = utils.getAttributes()
    const scale = stage.scaleX()
    const W = stage.width()
    const H = stage.height()

    /**
     * scale이 달라질 때, `gutter`도 scale에 따라 달라지기 때문에 이미지의 위치는 (gutter.x * scale, gutter.y * scale) 만큼 이동함.
     * 즉, scale을 고려한 이미지의 원점 O_i는 `(stage.x() + gutter.x * scale, stage.y() + gutter.y * scale)`.
     *
     * 1. 이미지의 좌측 상단 점 O_i은 캔버스의 (0, 0)을 벗어나서는 안 됨.
     * 2. `gutter`가 포함된 캔버스의 크기를 `(W0, H0)`, 렌더링된 이미지의 크기를 `(W1, H1)`이라고 할 때,
     * 이미지의 우측 하단 점 (O_i.x + W1 * scale, O_i.y + H1 * scale)은 캔버스의 (W, H)을 벗어나서는 안 됨.
     *
     * 이때, `W0 = stage.width()`이고, `H0 = stage.height()`
     * `W1 = W0 - 2 * gutter.x`이고, `H1 = H0 - 2 * gutter.y`와 같음.
     *  */
    const bottomX = W * (1 - scale) + (gutter.x - TRANSLATION_PADDING) * scale
    const topX = -(gutter.x - TRANSLATION_PADDING) * scale
    const rightY = H * (1 - scale) + (gutter.y - TRANSLATION_PADDING) * scale
    const leftY = -(gutter.y - TRANSLATION_PADDING) * scale

    const minX = isSandboxed ? -Infinity : Math.min(topX, bottomX)
    const maxX = isSandboxed ? Infinity : Math.max(topX, bottomX)
    const minY = isSandboxed ? -Infinity : Math.min(leftY, rightY)
    const maxY = isSandboxed ? Infinity : Math.max(leftY, rightY)

    return { minX, maxX, minY, maxY }
  }, [])

  const adjustMinMaxFromPosition = useCallback((bound: BoundCoordinates, stagePos: Position) => ({
    minX: Math.min(bound.minX, stagePos.x),
    maxX: Math.max(bound.maxX, stagePos.x),
    minY: Math.min(bound.minY, stagePos.y),
    maxY: Math.max(bound.maxY, stagePos.y),
  }), [])

  const randomizeDelta = useCallback((e: KonvaEventObject<TranslationEvent>) => {
    const pointer = getCursorPosition(e)

    /** Stage에서 drag 이벤트가 발생하는 경우 delta 값이 stage.x(), stage.y()에 반영되므로 별도의 delta 반환하지 않음 */
    if (isTargetStage(e)) {
      return { dX: 0, dY: 0 }
    }
    return {
      dX: (pointer.x + Math.random() * SMOOTH_TRANSLATION_FACTOR) - cursorPoint.current.x,
      dY: (pointer.y + Math.random() * SMOOTH_TRANSLATION_FACTOR) - cursorPoint.current.y,
    }
  }, [])

  const changeStageOrigin = useCallback((x: number, y: number) => {
    if (!stage) {
      return
    }
    stage.setAttrs({ x, y, origin: { x, y } })
    utils.origin(x, y, stage.scaleX())
  }, [stage])

  const setBasePoint = useCallback((e: KonvaEventObject<TranslationEvent>) => {
    const { x, y } = e.target.position()
    const sX = e.target.getStage()?.x() ?? 0
    const sY = e.target.getStage()?.y() ?? 0

    cursorPoint.current = getCursorPosition(e)
    shapeStart.current = { x, y }
    stageStart.current = { x: sX, y: sY }
  }, [])

  const freezeShapePosition = useCallback((e: KonvaEventObject<TranslationEvent>) => {
    e.target.position(shapeStart.current)
  }, [])

  const translateStageManually = useCallback((dX: number, dY: number) => {
    if (!stage) {
      return
    }
    const nextX = stage.x() + dX
    const nextY = stage.y() + dY
    const stageStart = { x: stage.x(), y: stage.y() }

    const { minX, maxX, minY, maxY } = adjustMinMaxFromPosition(getBoundMinMax(stage), stageStart)
    const adjustedX = clampMinMax(nextX, minX, maxX)
    const adjustedY = clampMinMax(nextY, minY, maxY)

    changeStageOrigin(adjustedX, adjustedY)
  }, [stage, getBoundMinMax])

  const translateStage = useCallback((e: KonvaEventObject<TranslationEvent>) => {
    const stage = e.target.getStage()

    if (!stage) {
      return false
    }
    if (!isTargetStage(e)) {
      freezeShapePosition(e)
    }
    if (except?.(stage)) {
      return when(e, keyRecord.current)
    }
    if (when(e, keyRecord.current)) {
      const pointer = getCursorPosition(e)
      const { dX, dY } = randomizeDelta(e)

      const nextX = stage.x() + dX
      const nextY = stage.y() + dY

      const { minX, maxX, minY, maxY } = adjustMinMaxFromPosition(getBoundMinMax(stage), stageStart.current)
      const adjustedX = clampMinMax(nextX, minX, maxX)
      const adjustedY = clampMinMax(nextY, minY, maxY)

      changeStageOrigin(adjustedX, adjustedY)
      cursorPoint.current = pointer

      return true
    }
    return false
  }, [when, except, getBoundMinMax])

  /**
   * FIXME:ksh: 휠 드래그로 캔버스 이동 시 가끔씩 위치가 튀는 문제 - 2024.04.30
   * dragEnd 시 translateStage에서 계산한 origin과 stage.position()이 다르게 나타남.
   * dragEnd 이벤트에서 내부 position 상태와 origin 값 일치시키는 방식으로 임시 조치. 추후 원인 파악해 수정 필요. - 2024.05.02
   */
  const syncWithInternalOrigin = useCallback((origin: Position) => () => {
    stage.setAttrs({ ...origin, origin })
  }, [stage])

  /**
   * 마우스 휠 드래그에서는 마우스 왼쪽 드래그와는 달리 window 밖에서 mouseUp 되더라도 dragEnd 이벤트가 발생하지 않아
   * 마우스가 다시 window 안으로 들어왔을 때 drag event가 계속 발생하는 문제 수정.
   * */
  useGlobalEvent({
    type: 'mouseover',
    handler: (e) => (e.buttons === 0 && stage?.stopDrag()),
  })

  useGlobalEvent({
    type: 'keydown',
    enabled: captureKeyEvents,
    handler: (e) => {
      if (!shouldSuppressKeyEvent()) {
        keyRecord.current = e.code
      }
    },
  })
  useGlobalEvent({
    type: 'keyup',
    enabled: captureKeyEvents,
    handler: () => { keyRecord.current = '' },
  })

  return {
    setBasePoint,
    translate: translateStage,
    translateBy: translateStageManually,
    freeze: freezeShapePosition,
    syncWith: syncWithInternalOrigin,
  }
}

const isTargetStage = (e: KonvaEventObject<TranslationEvent>) => e.target === e.target.getStage()

const getCursorPosition = (e: KonvaEventObject<TranslationEvent>) => e.target.getStage()?.getPointerPosition() ?? { x: 0, y: 0 }
