import BaseApi from '@/api/Base/BaseApi'
import { CreateHyperparametersPayload, HyperparametersBase } from '@/api/Hyperparameters/types'
import { ModelValueType } from '@/pages/const'

const ROOT = '/hyperparameters'
export default class HyperparametersApi extends BaseApi {
  createHyperparameters(payload: CreateHyperparametersPayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<HyperparametersBase>(`/project/${projectId}/hyperparameters/create`, rest)
  }

  deleteHyperparameters(hyperparametersId: string) {
    return this.agent.delete<HyperparametersBase>(`${ROOT}/${hyperparametersId}`)
  }

  getHyperparametersList(projectId: string, modelType: ModelValueType) {
    return this.agent.get<HyperparametersBase[]>(`${ROOT}/list`, {
      params: { project_id: projectId, model_type: modelType },
    })
  }

  getHyperparameters(hyperparametersId: string) {
    return this.agent.get<HyperparametersBase>(`${ROOT}/${hyperparametersId}`)
  }
}
