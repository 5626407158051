import React, { forwardRef, FunctionComponent } from 'react'
import classNames from 'classnames'
import styled, { RuleSet } from 'styled-components'
import { TFunction } from 'i18next'
import { get } from 'lodash'

import { useI18n } from '@ahha/i18n/i18n'
import { buttonColor, buttonSize, buttonType, buttonVariant } from '@ahha/stableComponents/Button/types'

interface StyledButtonProps {
  color: buttonColor
  width?: string
  height?: string
  css?: RuleSet
}

const StyledButton = styled.button<StyledButtonProps>`
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ theme, height }) => height || theme.variables.pxToRem(40)};
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.variables.pxToRem(8)} ${theme.variables.pxToRem(24)}`};
  display: flex;
  align-items: center;
  justify-content: center;
  &.isIcon {
    justify-content: space-between;
  }
  gap: 0.25rem;
  cursor: pointer;
  background-color: unset;
  border: unset;

  > svg {
    width: ${({ theme }) => theme.variables.pxToRem(24)};
    height: ${({ theme }) => theme.variables.pxToRem(24)};
  }

  > span {
    display: inline-block;
    width: fit-content;
    font-weight: 500;
    font-size: 1rem;
  }

  &.md {
    width: ${({ width }) => width || 'max-content'};
    height: ${({ theme, height }) => height || theme.variables.pxToRem(32)};
    padding: ${({ theme }) => `${theme.variables.pxToRem(4)} ${theme.variables.pxToRem(12)}`};
    > span {
      font-size: ${({ theme }) => theme.variables.pxToRem(14)};
    }
    > svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &.sm {
    width: ${({ width }) => width || 'max-content'};
    height: ${({ theme, height }) => height || theme.variables.pxToRem(28)};
    padding: ${({ theme }) => `${theme.variables.pxToRem(2)} ${theme.variables.pxToRem(12)}`};
    > span {
      font-weight: 400;
      font-size: ${({ theme }) => theme.variables.pxToRem(12)};
    }
    > svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }


  &.filled {
    background-color: ${({ theme, color }) => get(theme, `colors.bg[${color}]`)};
    color: ${({ theme }) => theme.colors.text.inverse};
    border: ${({ theme, color }) => `1px solid ${get(theme, `colors.bg[${color}]`)}`};
    &.primary:hover {
      background-color: ${({ theme }) => theme.colors.bg.primaryHover};
      border-color: ${({ theme }) => theme.colors.bg.primaryHover};
    }
    &.disabled {
      background-color: ${({ theme, color }) => theme.colors.bg.disabled} !important;
      &.error {
        background-color: ${({ theme }) => theme.colors.bg.errorDisabled} !important;
        color: ${({ theme }) => theme.colors.text.inverse};
      }
      &:hover {
        border: none;
      }
    }
  }

  &.outlined {
    background-color: unset;
    border: ${({ theme, color }) => `1px solid ${get(theme, `colors.bg[${color}]`)}`};
    color: ${({ theme, color }) => theme.colors.text[color]};
    &.primary {
      border: ${({ theme, color }) => `1px solid ${theme.colors.border.border4}`};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.bg.hover};
    }
    &.disabled {
      background-color: unset;
      &.error {
        border: ${({ theme, color }) => `1px solid ${theme.colors.bg.errorDisabled}`};
        color: ${({ theme, color }) => theme.colors.bg.errorDisabled};
      }
    }
  }

  &.transparent {
    border: unset !important;
    background-color: unset !important;
    color: ${({ theme, color }) => theme.colors.text[color]};
    &:not(.disabled):hover {
      background-color: ${({ theme }) => theme.colors.bg.hover} !important;
    }
  }
  &.error {
    &.filled:hover {
      background-color: ${({ theme }) => theme.colors.bg.error};
    }
    &:not(.filled):hover {
      //background-color: unset;
    }
  }
  &.inverse {
    //TODO: change background color
    &:hover {}
  }
  &.disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    border: ${({ theme }) => `1px solid ${theme.colors.bg.disabled}`};
    cursor: default;
  }
`
interface ButtonProps {
  content: string | ((t: TFunction) => string);
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  type?: buttonType;
  color?: buttonColor;
  disabled?: boolean;
  selected?: boolean;
  variant?: buttonVariant;
  size?: buttonSize;
  leftIcon?: FunctionComponent;
  rightIcon?: FunctionComponent;
  width?: string;
  height?: string;
  css?: RuleSet;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    content,
    onClick,
    className,
    color = 'primary',
    type = 'button',
    disabled = false,
    selected = false,
    variant = 'filled',
    size = 'lg',
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    width,
    height,
    css,
  } = props
  const { t } = useI18n()

  const buttonClasses = classNames('common-button', {
    disabled,
    selected,
    [variant]: true,
    [color]: true,
    [size]: true,
    isIcon: !!LeftIcon || !!RightIcon,
  })

  return (
    <StyledButton
      className={`${buttonClasses} ${className || ''}`}
      onClick={onClick}
      color={color}
      ref={ref}
      width={width}
      height={height}
      type={type}
      disabled={disabled}
      css={css}
    >
      {LeftIcon && <LeftIcon />}
      <span>{t(content)}</span>
      {RightIcon && <RightIcon />}
    </StyledButton>
  )
})
export default Button
