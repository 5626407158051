import BaseApi from '@/api/Base/BaseApi'
import {
  CreatePreprocessingNodePayload,
  PreprocessingNodeInfo,
  UpdatePreprocessingNodePayload
} from '@/api/PreprocessingNode/types'

const ROOT = '/preprocessing'

export default class PreprocessingNodeApi extends BaseApi {
  createPreprocessingNode(payload: CreatePreprocessingNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<PreprocessingNodeInfo>(`/project/${projectId}/preprocessing/create`, rest)
  }

  getPreprocessingNodeDetail(preprocessingNodeId: string) {
    return this.agent.get<PreprocessingNodeInfo>(`${ROOT}/${preprocessingNodeId}`)
  }

  updatePreprocessingNode(payload: UpdatePreprocessingNodePayload) {
    const { preprocessingNodeId, ...rest } = payload
    return this.agent.put<PreprocessingNodeInfo>(`${ROOT}/${preprocessingNodeId}`, rest)
  }

  deletePreprocessingNode(preprocessingNodeId: string) {
    return this.agent.delete(`${ROOT}/${preprocessingNodeId}`)
  }
}
