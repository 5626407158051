import BaseApi from '@/api/Base/BaseApi'
import {
  AddCommentPayload,
  CreateCommentPayload,
  CreateReviewNodePayload,
  GetAllDatasetsForReviewResponse,
  LabelingNodeInfo,
  LabelingNodeItem,
  LabelingObject,
  ReviewNodeInfo,
  StartReviewNodePayload,
  UpdateReviewNodePayload
} from '@/api/LabelingNode/types'
import { UserInfo } from '@/api/User/types'

export default class LabelingNodeReviewApi extends BaseApi {
  createReviewNode(payload: CreateReviewNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ReviewNodeInfo>(`/project/${projectId}/review/create`, rest)
  }

  getReviewNodeDetail(reviewNodeId: string) {
    return this.agent.get<ReviewNodeInfo>(`/review/${reviewNodeId}`)
  }

  updateReviewNode(payload: UpdateReviewNodePayload) {
    const { reviewNodeId, ...rest } = payload
    return this.agent.put<ReviewNodeInfo>(`/review/${reviewNodeId}`, rest)
  }

  getAvailableReviewers(projectId: string) {
    return this.agent.get<UserInfo[]>(`/project/${projectId}/available-reviewers`)
  }

  getAllDatasetsForReview(reviewNodeId: string) {
    return this.agent.get<GetAllDatasetsForReviewResponse>(`/review/${reviewNodeId}/datasets-all`)
  }

  startReviewNode(payload: StartReviewNodePayload) {
    const { reviewNodeId, labelingNodeId } = payload
    return this.agent.post(`/review/${reviewNodeId}/labeling/${labelingNodeId}/start-review`)
  }

  // Review objects
  createComment(payload: CreateCommentPayload) {
    const { fileId, comment, region, metadata } = payload

    return this.agent.post<LabelingObject>('/labeling/review-object', {
      labelingItemId: fileId,
      metadata,
      comment,
      region,
    })
  }

  appendComment(payload: AddCommentPayload) {
    const { id, comment } = payload
    return this.agent.post<LabelingObject>(`/labeling-object/${id}/comment`, { comment })
  }

  removeComment(objectId: string, commentId: string) {
    return this.agent.delete<LabelingObject>(`/labeling-object/${objectId}/comment/${commentId}`)
  }

  toggleResolveComment(objectId: string) {
    return this.agent.post<LabelingObject>(`/labeling-object/${objectId}/confirm-review-object`)
  }

  /** @deprecated  */
  requestReview(nodeId: string) {
    return this.agent.post(`/labeling/${nodeId}/review`)
  }

  /** @deprecated  */
  cancelReviewRequest(nodeId: string) {
    return this.agent.post(`/labeling/${nodeId}/cancel-review-request`)
  }

  toggleReviewForFileFinished(sourceId: string) {
    return this.agent.post<LabelingNodeItem>(`/labeling-item/${sourceId}/confirm`)
  }

  confirmAllLabelingItems(labelingNodeId: string) {
    return this.agent.post<LabelingNodeInfo>(`/labeling/${labelingNodeId}/confirm-all-items`)
  }

  finalizeReview(nodeId: string) {
    return this.agent.post(`/labeling/${nodeId}/finalize-review`)
  }

  rejectItems(nodeId: string) {
    return this.agent.post(`/labeling/${nodeId}/reject-items`)
  }

  /** @deprecated  */
  requestRework(nodeId: string) {
    return this.agent.post(`/labeling/${nodeId}/rework`)
  }

  deleteReviewNode(reviewNodeId: string) {
    return this.agent.delete(`/review/${reviewNodeId}`)
  }
}
