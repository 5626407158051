import { RefObject, useCallback, useEffect, useRef } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import useStageCursor from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useStageCursor'
import { getPointerPosition, registerEvent, unregisterEvent } from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/utils'
import useRevertCursorOnQuit from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useRevertCursorOnQuit'
import { isAbsBounded } from '@chart/util/common/mathUtils'
import { getOrthogonalUnitVector, getVectorAngle } from '@ahha/stableComponents/Canvas/utils/math'
import { useFlagMode } from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useFlagMode'

type NodeTarget = Konva.Shape | Konva.Stage

interface EdgeResizeCursorParams<T> {
  enabled: boolean
  ref: RefObject<T>
  revertOnTarget?: (target: NodeTarget) => boolean
  initialRotation: number
}

export const useEdgeResizeCursor = <T extends Konva.Shape>({
  enabled,
  ref,
  initialRotation,
  revertOnTarget,
}: EdgeResizeCursorParams<T>) => {
  const [setCursor, revertCursor, stage] = useStageCursor()
  const [isResizing, startResize, endResize] = useFlagMode()

  useRevertCursorOnQuit(enabled, () => {
    prevPoint.current = { x: 0, y: 0 }
    endResize()
  })

  const prevPoint = useRef({ x: 0, y: 0 })
  const accumulatedDiff = useRef({ dX: 0, dY: 0 })
  const initialCursor = useRef('default')

  const calcAccumulatedDelta = (x: number, y: number) => {
    const { x: prevX, y: prevY } = prevPoint.current
    const { dX, dY } = accumulatedDiff.current

    accumulatedDiff.current = { dX: dX + Math.abs(x - prevX), dY: dY + Math.abs(y - prevY) }

    return accumulatedDiff.current
  }

  const setInitialPoint = useCallback((e: KonvaEventObject<MouseEvent>) => {
    startResize()
    prevPoint.current = getPointerPosition(e)
    accumulatedDiff.current = { dX: 0, dY: 0 }
  }, [])

  const setInitialCursor = useCallback(() => {
    const cursor = getResizeCursor(initialRotation + 90)

    initialCursor.current = cursor
    setCursor(cursor)
  }, [initialRotation])

  const setEdgeResizeCursor = useCallback((e: KonvaEventObject<MouseEvent>) => {
    const { x: x0, y: y0 } = prevPoint.current ?? {}

    if (!isResizing() || !x0 || !y0) {
      return
    }
    const fallback = initialCursor.current
    const { x, y } = getPointerPosition(e)
    const { dX, dY } = calcAccumulatedDelta(x, y)

    /**
     * 1. 드래그 시작점 근처에서는 조금만 움직여도 각도가 크게 변하므로 근처에서는 `initialCursor`로 유지
     * 2. 포인터를 움직이다가 다시 시작점 근처로 돌아왔을 때도 `initialCursor`로 유지하는 건 어색하므로 `x - x0` 대신 `accumulatedDx`로 판단
     * */
    if (isAbsBounded(dX, CURSOR_INIT_THRESHOLD) && isAbsBounded(dY, CURSOR_INIT_THRESHOLD)) {
      setCursor(fallback)
      return
    }
    if (isAbsBounded(x - x0, DRAG_DISTANCE_THRESHOLD) && isAbsBounded(y - y0, DRAG_DISTANCE_THRESHOLD)) {
      return
    }
    const deg = getVectorAngle(...getOrthogonalUnitVector([x0, y0], [x, y]))

    setCursor(getResizeCursor(deg, fallback))
    prevPoint.current = { x, y }
  }, [])

  const revertEdgeResizeCursor = useCallback((e: KonvaEventObject<MouseEvent>) => {
    if (!isResizing()) {
      return
    }
    if (revertOnTarget?.(e.target)) {
      revertCursor()
    }
    prevPoint.current = { x: 0, y: 0 }
    endResize()
  }, [])

  useEffect(() => () => {
    if (isResizing()) {
      revertCursor()
    }
  }, [])

  useEffect(() => {
    const shape = ref.current

    if (enabled && shape) {
      registerEvent(shape, 'mousemove', setInitialCursor)
    }

    return () => unregisterEvent(shape, 'mousemove', setInitialCursor)
  }, [enabled, initialRotation])

  useEffect(() => {
    const shape = ref.current

    if (enabled && shape) {
      registerEvent(shape, 'mousedown', setInitialPoint)
      registerEvent(shape, 'mouseleave', revertCursor)

      registerEvent(stage, 'dragmove', setEdgeResizeCursor)
      registerEvent(stage, 'mouseup', revertEdgeResizeCursor)
    } else {
      revertCursor()
    }

    return () => {
      unregisterEvent(shape, 'mousedown', setInitialPoint)
      unregisterEvent(shape, 'mouseleave', revertCursor)
      unregisterEvent(stage, 'dragmove', setEdgeResizeCursor)
      unregisterEvent(stage, 'mouseup', revertEdgeResizeCursor)
    }
  }, [enabled])
}

const BOUNDARY_DEGREE = 45 / 2

const CURSOR_INIT_THRESHOLD = 5

const DRAG_DISTANCE_THRESHOLD = 7

const isWithinThreshold = (num: number, center: number) => num >= center - BOUNDARY_DEGREE && num < center + BOUNDARY_DEGREE

const getResizeCursor = (deg: number, fallback?: string) => {
  const positiveDeg = (deg < 0 || deg >= 360) ? (deg + 360) % 360 : deg

  if (isWithinThreshold(positiveDeg, 0) || isWithinThreshold(positiveDeg, 180)) {
    return 'ns-resize'
  }
  if (isWithinThreshold(positiveDeg, 45) || isWithinThreshold(positiveDeg, 225)) {
    return 'nesw-resize'
  }
  if (isWithinThreshold(positiveDeg, 90) || isWithinThreshold(positiveDeg, 270)) {
    return 'ew-resize'
  }
  if (isWithinThreshold(positiveDeg, 135) || isWithinThreshold(positiveDeg, 315)) {
    return 'nwse-resize'
  }
  if (isWithinThreshold(positiveDeg, 360)) {
    return 'ns-resize'
  }
  return fallback ?? 'default'
}
