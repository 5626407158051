import { Node } from '@xyflow/react'
import { NodeTypes } from '@/pages/RenewedPipeline/Nodes/types'
import { ModelValueType } from '@/pages/const'

/**
 * Node 타입:
 * id (노드의 ObjectId - 고로 해당 이아디와 data 객체 밑 _id는 동일)
 * type (dataset | labeling | review | train | validation | inference),
 * position ({ x: number, y: number }),
 * data (노드 get 해온 정보)
 * */
export interface PipelineState {
  nodes: Node[],
  placeholder: {
    position: { x: number, y: number },
    sourceId?: string,
    sourceType?: NodeTypes,
    isContextBound?: boolean,
  } | undefined,
  tasksInProgress: {
    type: ClientTaskTypes,
    nodeId: string,
    modelType?: ModelValueType
  }[]
}

export type ClientTaskTypes = 'train' | 'validation' | 'inference' | 'preprocessingResult'

export const INITIAL_STATE: PipelineState = {
  nodes: [],
  placeholder: undefined,
  tasksInProgress: [],
}
