/* eslint-disable camelcase */
import { ReactNode } from 'react'
import {
  Mutation,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'
import { ErrorResponse } from '@/api/Base/types'

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError<ErrorResponse, any>
  }
}

interface QueryProviderProps {
  children: ReactNode
}

const getServerErrorMessage = ({ response }: AxiosError<ErrorResponse>) => {
  const { status_code, message } = response?.data ?? {}

  return message ?? 'Unknown Error'
}

const toastErrorMessage = (error: Error) => {
}

const mutationErrorLogger = (error: Error, mutation: Mutation, variables: unknown) => {
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { enableErrorToast = true } = query.meta ?? {}
      if (!(error instanceof AxiosError)) {
        return
      }
      const message = getServerErrorMessage(error)
      console.error(message)
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, _, mutation) => {
      const { enableErrorToast = true } = mutation.meta ?? {}
      if (!(error instanceof AxiosError)) {
        return
      }
      const message = getServerErrorMessage(error)
      console.error(message)
      /* if (enableErrorToast) {
        toastErrorMessage(error)
      } */
    },
  }),
  defaultOptions: {
    queries: {
      // TODO: 바로 바뀌는 화면들을 봐야하는 경우가 더 많은것 같아 임시 주석처리
      // staleTime: 30 * 1000,
      retry: (count, error) => {
        const { status } = error?.response ?? {}

        if (status && status >= 500) {
          return false
        }
        return count < 1
      },
      throwOnError: true,
    },
    mutations: {
      throwOnError: true,
    },
  },
})

export const QueryProvider = ({ children }: QueryProviderProps) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    {children}
  </QueryClientProvider>
)
