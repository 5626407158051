import { QueryKey } from '@tanstack/react-query'

export const DEFAULT_LIST_OPTIONS = {
  keyword: '',
  sortKey: 'name',
  sortOrder: 'asc',
}

export const withOptions = <O extends Record<string, any> = CommonListParams>(
  key: QueryKey,
  options?: O
) => (!options ? key : [...key, options])

export interface CommonListParams {
  keyword?: string
  page?: number
  itemsPerPage?: number
  sortKey?: string
  sortDir?: string
}

export const imageKeys = {
  tifImage: (src: string) => ['tifImage', src],
}
