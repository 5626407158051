import { MouseEvent, useRef } from 'react'

interface ClickHandlers<T> {
  enableDoubleClick?: boolean
  onClick: (e: MouseEvent<T>, ...args: any) => void
  onShiftClick?: (e: MouseEvent<T>, ...args: any) => void
  onCtrlClick?: (e: MouseEvent<T>, ...args: any) => void
  onDoubleClick: (e: MouseEvent<T>, ...args: any) => void
}

/**
 * 클릭과 더블 클릭을 구별짓기 위한 클릭 이벤트 사이의 시간 간격.
 * 너무 길게 설정하면 사용자 경험에 좋지 않고, 너무 짧게 설정하면 더블 클릭이 씹히는 문제가 발생할 수 있으므로 주의
 * */
const THRESHOLD_TIME_SPAN = 150

export const useMultiClick = <T extends HTMLElement>({
  enableDoubleClick,
  onClick,
  onShiftClick,
  onCtrlClick,
  onDoubleClick,
}: ClickHandlers<T>) => {
  const clickCount = useRef(0)
  const singleClickTimer = useRef<NodeJS.Timeout>()

  const handleClick = (e: MouseEvent<T>, ...args: any) => {
    const prevCount = clickCount.current
    const thresholdTime = enableDoubleClick ? THRESHOLD_TIME_SPAN : 0

    clickCount.current += 1
    if (prevCount === 0) {
      const { shiftKey, ctrlKey, metaKey } = e

      singleClickTimer.current = setTimeout(() => {
        if (shiftKey) {
          onShiftClick?.(e, ...args)
        } else if (ctrlKey || metaKey) {
          onCtrlClick?.(e, ...args)
        } else {
          onClick(e, ...args)
        }
        clickCount.current = 0
      }, thresholdTime)
    } else if (prevCount === 1) {
      clearTimeout(singleClickTimer.current)
    }
  }

  const handleDoubleClick = (e: MouseEvent<T>) => {
    if (!enableDoubleClick) {
      return
    }
    clearTimeout(singleClickTimer.current)
    clickCount.current = 0
    onDoubleClick(e)
  }

  return {
    register: () => ({
      onClick: handleClick,
      onDoubleClick: handleDoubleClick,
    }),
    onClick: handleClick,
    onDoubleClick: handleDoubleClick,
  }
}
