import React, { useEffect, useRef, useState } from 'react'

import Button from '@ahha/stableComponents/Button/Button'
import ImgButton from '@ahha/stableComponents/Button/ImgButton'
import { useI18n } from '@ahha/i18n/i18n'
import { TOAST_STATES, ToastType, ToastValueType } from '@ahha/utils/@types/types'
import { StyledToastItem } from '@ahha/stableComponents/Notification/styled'
import { useGlobalNotification } from '@/context/notificationContext'

import { ReactComponent as CloseIcon } from '@/assets/icons/toast/close.svg'
import { ReactComponent as DefaultInfoIcon } from '@/assets/icons/toast/default-info.svg'
import { ReactComponent as InfoIcon } from '@/assets/icons/toast/info.svg'
import { ReactComponent as WarningIcon } from '@/assets/icons/toast/alert-circle.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/toast/x-circle.svg'
import { ReactComponent as SuccessIcon } from '@/assets/icons/toast/check.svg'

const getIcon = (status: ToastValueType) => {
  if (status === TOAST_STATES.ERROR) {
    return <ErrorIcon />
  }
  if (status === TOAST_STATES.WARNING) {
    return <WarningIcon />
  }
  if (status === TOAST_STATES.SUCCESS) {
    return <SuccessIcon />
  }
  if (status === TOAST_STATES.INFO) {
    return <InfoIcon />
  }
  if (status === TOAST_STATES.DEFAULT) {
    return <DefaultInfoIcon />
  }
  return <InfoIcon />
}

const NotificationItem = (props: ToastType) => {
  const { id, autoHide = true, title, message, customButtonList, status, showCloseButton = true } = props

  const [cleared, setCleared] = useState(false)
  const functionTimerRef = useRef<NodeJS.Timeout>()
  const stateTimeRef = useRef<NodeJS.Timeout>()

  const { removeNotification } = useGlobalNotification()
  const { t } = useI18n()

  useEffect(() => {
    if (autoHide) {
      stateTimeRef.current = setTimeout(() => {
        setCleared(true)
      }, 5000)
    }
    return () => {
      clearTimeout(stateTimeRef.current)
      clearTimeout(functionTimerRef.current)
    }
  }, [])

  useEffect(() => {
    if (cleared) {
      functionTimerRef.current = setTimeout(() => {
        removeNotification(id)
      }, 200)
    }
  }, [cleared])

  const handleClearNotification = () => {
    setCleared(true)
  }

  return (
    <StyledToastItem {...props} className={cleared ? 'notificationClear' : ''}>
      <div className="toast__status-color-bar" />
      {getIcon(status)}
      <div className="toast__text-content-box">
        {title && <h5>{title}</h5>}
        {message && <span>{message}</span>}
        {customButtonList && (
          <div className="toast__custom-button-box">
            {customButtonList.map((btn) => (
              <Button
                key={btn.title}
                content={t(btn.title)}
                size="sm"
                color="primary"
                variant="outlined"
                onClick={btn.onClick ? () => {
                  btn.onClick!()
                  handleClearNotification()
                } : handleClearNotification}
              />
            ))}
          </div>
        )}
      </div>
      {showCloseButton && (
        <ImgButton
          src={CloseIcon}
          size="sm"
          alt="clear"
          onClick={handleClearNotification}
          variant="transparent"
          width="1.5rem"
          height="1rem"
        />
      )}
    </StyledToastItem>
  )
}

export default NotificationItem
