import { LabelingFileStatusType } from '@/api/LabelingNode/types'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'

export const ZoomBehavior = {
  ZOOM_IN: 'zoom_in',
  ZOOM_OUT: 'zoom_out',
} as const

export const AnnotationLabelingType = {
  ANOMALY_DETECTION: 'ANOMALY_DETECTION',
  SEGMENTATION: 'SEGMENTATION',
  CLASSIFICATION: 'CLASSIFICATION',
  OBJECT_DETECTION: 'OBJECT_DETECTION',
}

export const LABELING_COMPLETED_ITEMS: LabelingFileStatusType[] = [LABEL_FILE_STATUS.FINISHED, LABEL_FILE_STATUS.COMPLETED]
export const REVIEW_COMPLETED_ITEMS: LabelingFileStatusType[] = [LABEL_FILE_STATUS.REJECTED, LABEL_FILE_STATUS.COMPLETED]
