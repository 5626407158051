import { createAsyncThunk } from '@reduxjs/toolkit'
import { set, isEmpty, map, omit, get, forEach, filter, findIndex } from 'lodash'

import { LabelingObjectData, ThunkConfig } from '@/stores/slices/Labeling/types'
import { getSettledFiles, serializeObject, transformAnnotObjects } from '@/stores/slices/Labeling/utils'

import { socket } from '@/socket'
import { API } from '@/api'
import { queryClient } from '@/api/react-query/queryClient'
import { labelingNodeKeys } from '@/api/react-query/queryKeys/labelingNode'
import {
  CreateTagObjectPayload,
  CreateTagToImagesPayload,
  LabelingNodeItem,
  LabelingObject,
  ObjectToolType
} from '@/api/LabelingNode/types'
import { loadLabelingItems } from '@/stores/slices/Labeling/thunks/files'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'

interface LoadObjectsParams {
  index: number;
  /**
   * 불필요한 API 요청을 보내지 않기 위해 현재 이미지의 스냅샷이 존재하면 `loadAnnotObjects`를 호출하더라도 API 요청을 보내지 않음.
   * 클래스 삭제 등의 동작으로 기존 스냅샷이 유효하지 않은 상태가 되면 스냅샷이 존재하더라도 API 요청을 보낼 수 있도록 함.
   */
  invalidate?: boolean;
}

interface LoadObjectsResponse {
  objectData: LabelingObjectData[] | undefined,
  settledFile: LabelingNodeItem,
  fetchedFile: LabelingNodeItem,
  addSnapshot: boolean,
  trackedObjects?: LabelingObjectData[],
}

type UndoRedoActionPayload = {
  currentObjectState: LabelingObject | null,
  prevObjectState: LabelingObject,
}

export const loadObjects = createAsyncThunk<LoadObjectsResponse, LoadObjectsParams, ThunkConfig>(
  'labeling/load-objects',
  async ({ index }, thunkAPI) => {
    const state = thunkAPI.getState().labeling
    const { labelingNodeId, currentIndex, originalFiles, files, annotation: prevSnapshot, tool: { isTracking } } = state

    let trackedObjects
    const file = getSettledFiles(state, index)
    const fetchedFile = await queryClient.fetchQuery({
      queryKey: labelingNodeKeys.file(labelingNodeId, file._id),
      queryFn: () => API().Labeling.getFileDetails(file._id),
    })
    // FIXME: duration??
    await API().Labeling.updateUserMeta({ labelingNodeId, lastIndex: findIndex(originalFiles, { _id: file._id }), lastLabelingItemId: file._id, duration: 0 })

    // FIXME: 완료 처리 된 아이템인지 확인 필요
    if (isTracking && isEmpty(fetchedFile.labelingObjects) && fetchedFile.status !== LABEL_FILE_STATUS.FINISHED) {
      const currentFileId = get(files[currentIndex], '_id', '')
      const currentObjects = prevSnapshot[currentFileId].object
      const objectsToSave = map(currentObjects, (o) => omit(serializeObject(o, '', o.name), ['labelingItemId', 'labelingObjectId']))
      const { data: savedObjects } = await API().Labeling.saveObjects({ labelingItemId: file._id, labelingObjects: objectsToSave })
      trackedObjects = savedObjects
    }

    if (file._id in prevSnapshot) {
      return {
        objectData: transformAnnotObjects(fetchedFile.labelingObjects),
        settledFile: file,
        fetchedFile,
        addSnapshot: false,
        trackedObjects: transformAnnotObjects(trackedObjects || []),
      }
    }

    if (!labelingNodeId) {
      return {
        objectData: [],
        settledFile: file,
        fetchedFile,
        addSnapshot: false,
      }
    }

    return {
      objectData: transformAnnotObjects(fetchedFile.labelingObjects),
      settledFile: file,
      fetchedFile,
      addSnapshot: true,
      trackedObjects: transformAnnotObjects(trackedObjects || []),
    }
  }
)

/** @deprecated */
export const addTags = createAsyncThunk<LabelingObject[], { itemIds: string[], classId: string }, ThunkConfig>(
  'labeling/add-tags',
  async ({ itemIds, classId }, thunkAPI) => {
    const { annotation } = thunkAPI.getState().labeling
    const finishedItemIds = filter(itemIds, (id) => annotation[id].object.length === 0)
    const addedObjects = await Promise.all(itemIds.map((id) => API().Labeling.createObject(_serializeTagObjects(id, classId))))
    await Promise.all(map(finishedItemIds, (id) => API().Labeling.toggleFileFinished(id)))
    thunkAPI.dispatch(loadLabelingItems(thunkAPI.getState().labeling.labelingNodeId))
    return addedObjects
  }
)

/** @deprecated */
export const deleteTags = createAsyncThunk<LabelingObject[], { objectIds: string[], itemIds: string[] }, ThunkConfig>(
  'labeling/delete-tags',
  async ({ objectIds, itemIds }, thunkAPI) => {
    const { annotation } = thunkAPI.getState().labeling
    const unfinishedItemIds = filter(itemIds, (id) => annotation[id].object.length === 1)

    const deletedObjects = await Promise.all(objectIds.map((id) => API().Labeling.deleteObject(id)))
    await Promise.all(map(unfinishedItemIds, (id) => API().Labeling.toggleFileFinished(id)))
    thunkAPI.dispatch(loadLabelingItems(thunkAPI.getState().labeling.labelingNodeId))
    return deletedObjects
  }
)

export const addTag = createAsyncThunk<LabelingNodeItem, CreateTagObjectPayload, ThunkConfig>(
  'labeling/add-tag',
  async (payload, thunkAPI) => {
    const res = await API().Labeling.addTag(payload)
    return res
  }
)

export const addTagsToImages = createAsyncThunk<void, CreateTagToImagesPayload, ThunkConfig>(
  'labeling/add-tag-to-images',
  async (payload, thunkAPI) => {
    await API().Labeling.addTagToImages(payload)
    thunkAPI.dispatch(loadLabelingItems(thunkAPI.getState().labeling.labelingNodeId))
  }
)

export const deleteTag = createAsyncThunk<LabelingObject, string, ThunkConfig>(
  'labeling/remove-tag',
  async (objectId, thunkAPI) => {
    const res = await API().Labeling.deleteObject(objectId)
    return res
  }
)

const _serializeTagObjects = (itemId: string, classId: string, objectId?: string) => {
  const now = new Date()
  const payload = {
    labelingItemId: itemId,
    labelingClassId: classId,
    zOrder: 0,
    metadata: {
      createdAt: now,
      modifiedAt: now,
    },
    type: ObjectToolType.TAG,
  }
  if (objectId) {
    set(payload, 'labelingObjectId', objectId)
  }
  return payload
}

export const undoAction = createAsyncThunk<UndoRedoActionPayload | null, void, ThunkConfig>(
  'labeling/undo-action',
  async (_, thunkAPI) => {
    const { user } = thunkAPI.getState().user
    const { currentIndex, files, labelingNodeId } = thunkAPI.getState().labeling
    const currentItemId = get(files[currentIndex], '_id', '')
    try {
      const { currentObject, labelingHistory } = await API().Labeling.undoAction(currentItemId)
      socket.emitHandleUndo({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId: currentItemId,
        currentObjectState: currentObject,
        prevObjectState: labelingHistory.backupObject,
      } as any)
      return { currentObjectState: currentObject, prevObjectState: labelingHistory.backupObject }
    } catch (e) {
      console.error(e)
    }
    return null
  }
)

export const redoAction = createAsyncThunk<UndoRedoActionPayload | null, void, ThunkConfig>(
  'labeling/redo-action',
  async (_, thunkAPI) => {
    const { user } = thunkAPI.getState().user
    const { currentIndex, files, labelingNodeId } = thunkAPI.getState().labeling
    const currentItemId = get(files[currentIndex], '_id', '')
    try {
      const { currentObject, labelingHistory } = await API().Labeling.redoAction(currentItemId)
      socket.emitHandleRedo({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId: currentItemId,
        currentObjectState: currentObject,
        prevObjectState: labelingHistory.backupObject,
      } as any)
      return { currentObjectState: currentObject, prevObjectState: labelingHistory.backupObject }
    } catch (e) {
      console.error(e)
    }
    return null
  }
)
