import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { filter, find, findIndex, has, isEmpty, map, set } from 'lodash'

import { isIn } from '@ahha/utils/@types/typeChecks'
import { clampMinMax } from '@ahha/utils/number'
import { initializeClasses, loadClassification, loadLabelingData } from '@/stores/slices/Labeling/thunks/initialization'
import { transformAnnotObjects, getSettledFiles, transformComment } from '@/stores/slices/Labeling/utils'
import { filterLabelingItems, loadLabelingItems } from '@/stores/slices/Labeling/thunks/files'
import { addTag, deleteTag, loadObjects, redoAction, undoAction } from '@/stores/slices/Labeling/thunks/objects'

import { CURSOR_COLORS, initialState, PossibleShape, PossibleTool } from '@/stores/slices/Labeling/const'
import { LabelingSnapshot, LabelingState, Tool, Comment, LabelingObjectData } from '@/stores/slices/Labeling/types'
import { ToolType } from '@/pages/Labeling/types'
import { LabelingFileStatusType, LabelingNodeItem, LabelingObject, UserShortcut } from '@/api/LabelingNode/types'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'

import { addBox, addEllipse, addPolygon } from '@/stores/slices/Labeling/thunks/createObjects'
import {
  updateBox,
  updateEllipse,
  updateObjectClass,
  updatePolygon
} from '@/stores/slices/Labeling/thunks/updateObjects'
import { updateObjectReducers } from '@/stores/slices/Labeling/reducers/updateObjects'
import { removeObject } from '@/stores/slices/Labeling/thunks/removeObjects'
import { saveUserShortcuts } from '@/stores/slices/Labeling/thunks/save'
import { getUserClasses, updateClassOrder } from '@/stores/slices/Labeling/thunks/class'
import { updateFileReducers } from '@/stores/slices/Labeling/reducers/updateFiles'

const _updateFileInfo = (state: Draft<LabelingState>, itemId: string, snapshot: LabelingSnapshot) => {
  const files = getSettledFiles(state)
  const fileIndex = files.findIndex((f) => f._id === itemId)

  if (fileIndex !== -1) {
    files[fileIndex].status = snapshot.status
    files[fileIndex].numOfLabelObjects = snapshot.object.length
  }
}

export const _addSnapshot = (state: Draft<LabelingState>, itemId: string, snapshot: LabelingSnapshot) => {
  state.annotation[itemId] = snapshot
  _updateFileInfo(state, itemId, snapshot)
}

export const _getLastSnapshot = (state: Draft<LabelingState>, itemId: string) => state.annotation[itemId]

const _addObject = (state: Draft<LabelingState>, itemId: string, object: LabelingObjectData) => {
  const prevObjects = _getLastSnapshot(state, itemId).object ?? []
  _addSnapshot(state, itemId, { status: LABEL_FILE_STATUS.IN_PROGRESS, object: [...prevObjects, object] })
}
const _updateObject = (state: Draft<LabelingState>, itemId: string, object?: LabelingObjectData) => {
  if (object) {
    state.annotation[itemId].object = map(state.annotation[itemId].object, (o) => (o.id === object.id ? object : o))
  }
}

const _undoRedoObject = (state: Draft<LabelingState>, objectStates: { currentObjectState: LabelingObject | null, prevObjectState: LabelingObject } | null) => {
  if (objectStates) {
    const { currentObjectState, prevObjectState } = objectStates
    if (currentObjectState === null) {
      const itemId = prevObjectState.labelingItemId
      const objectId = prevObjectState._id
      state.annotation[itemId].object = filter(state.annotation[itemId].object, (o) => o.id !== objectId)
      return
    }
    const itemId = currentObjectState.labelingItemId
    const foundObject = find(state.annotation[itemId].object, { id: currentObjectState._id })
    const transformedObject = transformAnnotObjects([currentObjectState])[0]
    if (foundObject) {
      _updateObject(state, itemId, transformedObject)
    } else {
      state.annotation[itemId].object = [...state.annotation[itemId].object, transformedObject]
    }
  }
}

const _initFiles = (state: Draft<LabelingState>, files: (LabelingNodeItem | null)[], start: number, end: number) => {
  const annotation: LabelingState['annotation'] = {}
  const comments: LabelingState['comments'] = {}
  const nonNullRawFiles = files.filter((f): f is LabelingNodeItem => !!f)
  const initFiles = Array.from({ length: files.length }, () => null)

  nonNullRawFiles.forEach((f) => {
    annotation[f._id] = { status: f.status, object: transformAnnotObjects(f.labelingObjects || []) }
    comments[f._id] = transformComment.toClient(f.labelingObjects)
  })

  state.files = [
    ...initFiles.slice(0, start),
    ...nonNullRawFiles,
    ...initFiles.slice(end + 1),
  ]
  state.annotation = annotation
  state.comments = comments
}

const _initClassificationFiles = (state: Draft<LabelingState>, files: LabelingNodeItem[]) => {
  const annotation: LabelingState['annotation'] = {}
  files.forEach((f) => {
    annotation[f._id] = { status: f.status, object: transformAnnotObjects(f.labelingObjects) }
  })
  state.files = files
  state.annotation = annotation
}

export const labelingSlice = createSlice({
  name: 'labeling',
  initialState,
  reducers: {
    setCurrentImageIndex: (state, action: PayloadAction<number>) => {
      const max = state.files.length - 1

      state.currentIndex = clampMinMax(action.payload, 0, max)
    },
    setClass: (state, action: PayloadAction<string>) => {
      state.tool.class = action.payload
    },
    setTool: (state, action: PayloadAction<Tool>) => {
      if (!PossibleTool.includes(action.payload)) {
        console.error(`${action.payload} is not available tool`)
        return
      }
      state.tool.tool = action.payload

      if (isIn(PossibleShape, action.payload)) {
        state.tool.shape = action.payload
      }
    },
    setBrush: (state, action: PayloadAction<number>) => {
      state.tool.brush = action.payload
      state.tool.tool = ToolType.BRUSH
    },
    setOpacity: (state, action: PayloadAction<number>) => {
      state.tool.opacity = Math.min(Math.max(action.payload, 0), 100)
    },
    setMagicWandThreshold: (state, action: PayloadAction<number>) => {
      state.tool.magicWandThreshold = action.payload
      state.tool.tool = ToolType.MAGICWAND
    },
    toggleBrushAutoFill: (state, action: PayloadAction<void>) => {
      state.tool.isBrushAutoFill = !state.tool.isBrushAutoFill
    },
    toggleRgbChannel: (state, action: PayloadAction<keyof LabelingState['tool']['rgb']>) => {
      const { all, red, green, blue } = state.tool.rgb
      if (action.payload === 'all') {
        if (all) {
          state.tool.rgb = { all: false, red: false, blue: false, green: false }
        } else {
          state.tool.rgb = { all: true, red: true, blue: true, green: true }
        }
        return
      }
      if (action.payload === 'red') {
        state.tool.rgb = { ...state.tool.rgb, red: !red, all: Boolean(!red && green && blue) }
      }
      if (action.payload === 'green') {
        state.tool.rgb = { ...state.tool.rgb, green: !green, all: Boolean(red && !green && blue) }
      }
      if (action.payload === 'blue') {
        state.tool.rgb = { ...state.tool.rgb, blue: !blue, all: Boolean(red && green && !blue) }
      }
    },
    setTracking: (state, action: PayloadAction<boolean>) => {
      state.tool.isTracking = action.payload
    },
    setFileFilter: (state, action: PayloadAction<LabelingState['fileFilter']>) => {
      state.fileFilter = action.payload
      state.currentIndex = 0
    },
    createComment: (state, action: PayloadAction<Omit<Comment, 'fileId' | 'resolved'>>) => {
      const fileId = state.files[state.currentIndex]?._id
      if (fileId) {
        state.comments[fileId].push({
          resolved: false,
          ...action.payload,
        })
      }
    },
    updateUserShortcuts: (state, action: PayloadAction<UserShortcut[]>) => {
      state.userShortcuts = action.payload
    },
    // SOCKET EVENTS
    socketAddObject: (state, action: PayloadAction<LabelingObject>) => {
      const itemId = action.payload.labelingItemId
      const transformedObject = transformAnnotObjects([action.payload])
      _addObject(state, itemId, transformedObject[0])
    },
    socketDeleteObject: (state, action: PayloadAction<LabelingObject>) => {
      const { _id, labelingItemId } = action.payload
      const prevSnapshot = _getLastSnapshot(state, labelingItemId)
      const filtered = filter(prevSnapshot.object, (p) => p.id !== _id)
      _addSnapshot(state, labelingItemId, { status: prevSnapshot.status, object: filtered })
    },
    socketUpdateObject: (state, action: PayloadAction<{ itemId: string, updatedObject: LabelingObjectData }>) => {
      const { itemId, updatedObject } = action.payload
      _updateObject(state, itemId, updatedObject)
    },
    socketToggleItemStatus: (state, action: PayloadAction<{ itemId: string, updatedStatus: LabelingFileStatusType }>) => {
      const { itemId, updatedStatus } = action.payload
      state.annotation[itemId] = { ...state.annotation[itemId], status: updatedStatus }
      state.files = state.files.map((f) => (f?._id === itemId ? { ...f, status: updatedStatus } : f))
      state.originalFiles = state.originalFiles.map((f) => (f?._id === itemId ? { ...f, status: updatedStatus } : f))
    },
    socketUndoRedo: (state, action: PayloadAction<{ currentObjectState: LabelingObject | null, prevObjectState: LabelingObject }>) => _undoRedoObject(state, action.payload),
    socketCreateReviewObject: (state, action: PayloadAction<{ itemId: string, addedComment: LabelingObject }>) => {
      const { itemId, addedComment } = action.payload
      state.comments[itemId].push({
        id: addedComment._id,
        serverId: addedComment._id,
        resolved: addedComment.isConfirm ?? false,
        ...addedComment.region,
        comments: transformComment.subComment(addedComment.comments),
      })
    },
    socketDeleteComment: (state, action: PayloadAction<{ itemId: string, serverId: string, commentId: string, isDestroyed: boolean }>) => {
      const { itemId, serverId, commentId, isDestroyed } = action.payload
      if (isDestroyed) {
        state.comments[itemId] = filter(state.comments[itemId], (c) => c.id !== serverId)
      } else {
        state.comments[itemId] = map(state.comments[itemId], (c) => {
          if (c.id === serverId) {
            return { ...c, comments: filter(c.comments, (sc) => sc.id !== commentId) }
          }
          return c
        })
      }
    },
    socketToggleResolveComment: (state, action: PayloadAction<{ itemId: string, commentId: string, isResolved: boolean }>) => {
      const { itemId, commentId, isResolved } = action.payload
      state.comments[itemId] = map(state.comments[itemId], (c) => (c.id === commentId ? { ...c, resolved: isResolved } : c))
    },
    resetCurrentLabeling: () => initialState,
    setParentNodeId: (state, action: PayloadAction<string>) => {
      state.parentNodeId = action.payload
    },

    ...updateObjectReducers,
    ...updateFileReducers,
  },
  extraReducers: (builder) => {
    builder.addCase(loadLabelingData.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(loadLabelingData.fulfilled, (state, action) => {
      state.isInProgress = false
      const {
        labelingNodeId,
        files,
        start,
        end,
        datasetMeta,
        userMeta,
        annotation,
        userShortcuts,
        userIsReviewer,
      } = action.payload

      _initFiles(state, files, start, end)
      state.labelingNodeId = labelingNodeId
      state.name = annotation.name
      state.projectId = annotation.projectId
      state.classGroupId = annotation.classGroupId
      state.numOfLabelingData = annotation.numOfLabelingItems || annotation.numOfDatasetItems
      state.modelType = annotation.modelType || 'ANOMALY_DETECTION'
      state.datasetInfo = { ...datasetMeta }
      state.currentIndex = userMeta.lastIndex ?? 0
      state.initialNodeStatus = annotation.status
      state.userIsReviewer = userIsReviewer
      state.reviewers = annotation.reviewers
      state.fileFilter = []
      state.userCursorColor = CURSOR_COLORS[Math.floor(Math.random() * 5)]

      if (!has(userShortcuts, 'data') && userShortcuts !== undefined) {
        state.userShortcuts = userShortcuts?.shortCutKeySettings
      }
    })
    builder.addCase(loadLabelingData.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(loadClassification.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(loadClassification.fulfilled, (state, action) => {
      state.isInProgress = false
      const { labelingNodeId, files, start, end, datasetMeta, annotation } = action.payload

      _initFiles(state, files, start, end)
      state.labelingNodeId = labelingNodeId
      state.name = annotation.name
      state.fileFilter = []
      state.projectId = annotation.projectId
      state.modelType = annotation.modelType || 'CLASSIFICATION'
      state.datasetInfo = { ...datasetMeta }
    })
    builder.addCase(loadClassification.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(initializeClasses.pending, (state) => { state.isInProgress = true })
    builder.addCase(initializeClasses.fulfilled, (state, action) => {
      state.classes = action.payload
      state.tool.class = action.payload[0]?._id || null
    })
    builder.addCase(initializeClasses.rejected, (state) => { state.isInProgress = false })
    builder.addCase(getUserClasses.fulfilled, (state, action) => {
      state.classes = action.payload
    })
    builder.addCase(updateClassOrder.fulfilled, (state, action) => {
      state.classes = action.payload
    })
    builder.addCase(loadLabelingItems.fulfilled, (state, action) => {
      state.files = action.payload
      state.originalFiles = action.payload
      if (state.modelType === 'CLASSIFICATION') {
        _initClassificationFiles(state, action.payload)
      }
    })
    builder.addCase(loadObjects.fulfilled, (state, action) => {
      const { objectData, settledFile, fetchedFile, addSnapshot, trackedObjects } = action.payload
      const { index } = action.meta.arg
      const { _id } = getSettledFiles(state, index)
      state.comments = { ...state.comments, [_id]: transformComment.toClient(fetchedFile.labelingObjects) }

      if (addSnapshot && objectData) {
        state.annotation[settledFile._id] = { status: settledFile.status, object: objectData }
      }
      if (trackedObjects && !isEmpty(trackedObjects)) {
        state.annotation[settledFile._id] = { status: LABEL_FILE_STATUS.IN_PROGRESS, object: trackedObjects }
        const fileIndex = findIndex(state.files, { _id: settledFile._id })
        set(state.files, `[${fileIndex}].numOfLabelObjects`, trackedObjects.length)
      }
      if (index === 0 || index === state.files.length - 1) {
        state.tool.isTracking = false
      }
      state.currentIndex = index
    })
    builder.addCase(filterLabelingItems.fulfilled, (state, action) => {
      const { itemList, filters } = action.payload
      const annotation: LabelingState['annotation'] = {}
      const comments: LabelingState['comments'] = {}
      const initAnnotList = itemList.slice(0, 5)

      initAnnotList.forEach((f) => {
        annotation[f._id] = { status: f.status, object: transformAnnotObjects(f.labelingObjects || []) }
        comments[f._id] = transformComment.toClient(f.labelingObjects)
      })

      state.files = itemList
      state.fileFilter = filters
      state.currentIndex = 0
      state.annotation = annotation
      state.comments = comments
    })
    builder.addCase(saveUserShortcuts.fulfilled, (state, action) => {
      state.userShortcuts = action.payload.shortCutKeySettings
    })
    // ADD OBJECT
    builder.addCase(addBox.fulfilled, (state, action) => _addObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(addEllipse.fulfilled, (state, action) => _addObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(addPolygon.fulfilled, (state, action) => _addObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(addTag.fulfilled, (state, action) => {
      const updatedIndex = findIndex(state.files, (f) => f?._id === action.payload._id)
      set(state.files, `[${[updatedIndex]}].labelingObjects`, action.payload.labelingObjects)
    })
    // DELETE OBJECT
    builder.addCase(removeObject.fulfilled, (state, action) => {
      const { itemId, objectId } = action.payload
      const prevSnapshot = _getLastSnapshot(state, itemId)
      const filtered = filter(prevSnapshot.object, (p) => p.id !== objectId)
      _addSnapshot(state, itemId, { status: prevSnapshot.status, object: filtered })
    })
    builder.addCase(deleteTag.fulfilled, (state, action) => {
      const { _id, labelingItemId } = action.payload
      const updatedIndex = findIndex(state.files, (f) => f?._id === labelingItemId)
      set(state.files, `[${[updatedIndex]}].labelingObjects`, filter(state.files[updatedIndex]?.labelingObjects, (o) => o._id !== _id))
    })
    // UPDATE OBJECT
    builder.addCase(updateBox.fulfilled, (state, action) => _updateObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(updateEllipse.fulfilled, (state, action) => _updateObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(updatePolygon.fulfilled, (state, action) => _updateObject(state, action.payload.itemId, action.payload.object))
    builder.addCase(updateObjectClass.fulfilled, (state, action) => _updateObject(state, action.payload.itemId, action.payload.object))
    // UNDO / REDO OBJECT
    builder.addCase(undoAction.fulfilled, (state, action) => _undoRedoObject(state, action.payload))
    builder.addCase(redoAction.fulfilled, (state, action) => _undoRedoObject(state, action.payload))
  },
})

export const {
  setCurrentImageIndex,
  setClass,
  setTool,
  setBrush,
  setOpacity,
  setMagicWandThreshold,
  toggleBrushAutoFill,
  toggleRgbChannel,
  setTracking,
  setFileFilter,

  createComment,
  addComment,
  toggleResolveComment,
  repositionComment,
  updateCommentData,

  toggleFileFinished,
  setAllReviewRequested,
  updateUserShortcuts,

  destroyComment,
  removeComment,

  socketAddObject,
  socketDeleteObject,
  socketUpdateObject,
  socketToggleItemStatus,
  socketUndoRedo,
  socketCreateReviewObject,
  socketDeleteComment,
  socketToggleResolveComment,

  resetCurrentLabeling,
  setParentNodeId,
} = labelingSlice.actions

export default labelingSlice.reducer
