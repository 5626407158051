import { some } from 'lodash'
import { Draft } from '@reduxjs/toolkit'
import { isArray, isIn } from '@ahha/utils/@types/typeChecks'
import { LabelingState } from '@/stores/slices/Labeling/types'
import { LabelingFileStatusType } from '@/api/LabelingNode/types'
import { LABEL_FILE_STATUS, LABEL_FINISHED } from '@/api/LabelingNode/const'

export const updateFileReducers = {
  toggleFileFinished: (state: Draft<LabelingState>) => {
    const {
      currentIndex,
      files,
      fileFilter,
      initialNodeStatus,
      comments,
    } = state
    // const currentFilter = isArray(fileFilter) ? new Set(fileFilter) : new Set([fileFilter])
    // const filtered = fileFilter === 'ALL' ? files : files.filter((f) => f && currentFilter.has(f.status))
    const currentFile = files[currentIndex]
    const id = currentFile?._id || ''

    if (currentFile) {
      let nextStatus: LabelingFileStatusType
      const prevSnapshot = state.annotation[id]
      // 검수 노드인 경우
      if (initialNodeStatus === LABEL_FILE_STATUS.REQUEST_REVIEW || initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS) {
        const isComplete = currentFile.status === LABEL_FILE_STATUS.COMPLETED || currentFile.status === LABEL_FILE_STATUS.REJECTED
        const hasUnresolvedComments = some(comments[id], { resolved: false })
        // eslint-disable-next-line no-nested-ternary
        nextStatus = isComplete ? LABEL_FILE_STATUS.REVIEW_IN_PROGRESS : (hasUnresolvedComments ? LABEL_FILE_STATUS.REJECTED : LABEL_FILE_STATUS.COMPLETED)
      } else {
        // 라벨링 노드인 경우
        // 반려, 재작업 요청된 경우 FINISHED | REJECTED | COMPLETED 까지 확인 필요
        nextStatus = currentFile.status === LABEL_FILE_STATUS.FINISHED ? LABEL_FILE_STATUS.IN_PROGRESS : LABEL_FILE_STATUS.FINISHED
      }
      // state.annotation[id] = prevSnapshot.map((s) => ({ ...s, status: nextStatus }))
      state.annotation[id] = { ...prevSnapshot, status: nextStatus }
      state.files = state.files.map((f) => (f?._id === id ? { ...f, status: nextStatus } : f))
      state.originalFiles = state.originalFiles.map((f) => (f?._id === id ? { ...f, status: nextStatus } : f))
    }
  },
  setAllReviewRequested: (state: Draft<LabelingState>) => {
    state.files = state.files.map((f) => (f ? { ...f, status: LABEL_FILE_STATUS.REQUEST_REVIEW } : f))
  },
}

const toggleStatus = (current: LabelingFileStatusType) => {
  if (isIn(LABEL_FINISHED, current)) {
    return LABEL_FILE_STATUS.IN_PROGRESS
  }
  return LABEL_FILE_STATUS.FINISHED
}
