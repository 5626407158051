import BaseApi from '@/api/Base/BaseApi'
import {
  CreateValidationNodePayload,
  GetValidationStatusResponse,
  GetValidationResultResponse,
  UpdateValidationNodePayload,
  ValidationNodeInfo,
  UpdateThresholdPayload
} from '@/api/ValidationNode/types'
import { GetLabelingNodeItemsResponse } from '@/api/LabelingNode/types'
import { CommonListPayload } from '@/api/DatasetNode/types'

const ROOT = '/validation'
const defaultPaginationOptions = {
  itemsPerPage: 40,
  page: 0,
  sortDir: 'desc',
  sortKey: 'createdAt',
}

export default class ValidationNodeApi extends BaseApi {
  createValidationNode(payload: CreateValidationNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ValidationNodeInfo>(`/project/${projectId}/validation/create`, rest)
  }

  getValidationNodeDetail(validationNodeId: string) {
    return this.agent.get<ValidationNodeInfo>(`${ROOT}/${validationNodeId}`)
  }

  updateValidationNode(payload: UpdateValidationNodePayload) {
    const { validationNodeId, ...rest } = payload
    return this.agent.put<ValidationNodeInfo>(`${ROOT}/${validationNodeId}`, rest)
  }

  deleteValidationNode(validationNodeId: string) {
    return this.agent.delete(`${ROOT}/${validationNodeId}`)
  }

  startValidation(validationNodeId: string) {
    return this.agent.post<GetValidationStatusResponse>(`${ROOT}/${validationNodeId}/validate`)
  }

  stopValidation(validationNodeId: string) {
    return this.agent.put<GetValidationStatusResponse>(`${ROOT}/${validationNodeId}/validate/stop`)
  }

  getValidationStatus(validationNodeId: string) {
    return this.agent.get<GetValidationStatusResponse>(`${ROOT}/${validationNodeId}/validate/status`)
  }

  getValidationResult(validationNodeId: string) {
    return this.agent.get<GetValidationResultResponse>(`${ROOT}/${validationNodeId}/validate`)
  }

  getValidationItems(validationNodeId: string, options?: CommonListPayload) {
    const { itemsPerPage, page, sortDir, sortKey } = options ?? defaultPaginationOptions
    return this.agent.get<GetLabelingNodeItemsResponse>(`${ROOT}/${validationNodeId}/items`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  updateThreshold(payload: UpdateThresholdPayload) {
    const { validationNodeId, ...rest } = payload
    return this.agent.put<GetValidationStatusResponse>(`${ROOT}/${validationNodeId}/threshold`, rest)
  }
}
