import { StageStoreContext, StageStoreReturns } from '@ahha/stableComponents/Canvas/utils/Context/Stage/StageContext'
import { StageStore } from '@ahha/stableComponents/Canvas/utils/Context/Stage/useStageStore'
import Konva from 'konva'
import { RefObject, useContext, useMemo, useSyncExternalStore } from 'react'

type StageStoreSelector<T> = (state: StageStore) => T

interface StageServices {
  node: StageStoreReturns['node']
  isSandbox: StageStoreReturns['isSandbox']
  draggable: StageStoreReturns['draggable']
  origin: StageStoreReturns['origin']
  deferredScale: StageStoreReturns['deferredScale']
  sourceMeta: StageStoreReturns['sourceMeta']
  getAttributes: StageStoreReturns['getAttributes']
  viewAttributes: StageStoreReturns['setViewAttributes']
  listening: StageStoreReturns['listening']
}

export function useStage<T>(selector: StageStoreSelector<T>, stageRef?: RefObject<Konva.Stage>): [T, StageServices]

export function useStage(): StageServices

/**
 * @param selector 구독하고자 하는 Stage의 값을 선택하는 함수. 해당 함수가 반환하는 값이 변할 때만 컴포넌트가 재렌더링됨.
 * @param stageRef `useStage`를 사용하기 전에 반드시 `<Stage/>` ref를 등록해줘야 함에 주의.
 */
export function useStage<T>(selector?: StageStoreSelector<T>, stageRef?: RefObject<Konva.Stage>) {
  const store = useContext(StageStoreContext)

  if (!store) {
    throw new Error('useStage hook cannot be used outside of <CanvasMain />')
  }
  const { getAll, subscribe } = store
  const state = useSyncExternalStore(
    subscribe,
    () => selector?.(getAll())
  )

  const utils = useMemo<StageServices>(() => ({
    node: store.node,
    isSandbox: store.isSandbox,
    draggable: store.draggable,
    origin: store.origin,
    deferredScale: store.deferredScale,
    getAttributes: store.getAttributes,
    viewAttributes: store.setViewAttributes,
    sourceMeta: store.sourceMeta,
    listening: store.listening,
  }), [])

  if (!selector) {
    return utils
  }
  if (!stageRef) {
    return [state, utils]
  }
  return [state, utils]
}
