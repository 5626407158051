/* eslint-disable consistent-return */
import { GutterInfo } from '@ahha/stableComponents/Canvas/types/canvas'
import { Point2D } from '@ahha/stableComponents/Canvas/types/point'
import { INITIAL } from '@ahha/stableComponents/Canvas/utils/Context/Stage/const'
import { useAtomicStore } from '@ahha/utils/hooks'
import Konva from 'konva'
import { useCallback, useEffect } from 'react'

type GetSet<V, P extends unknown[] = [V]> = {
  (): V
  (...value: P): void
}

export type ObserveMode = 'observe-source' | 'sandbox'

interface Area {
  W: number
  H: number
}

interface StageOrigin {
  x: number
  y: number
  /** scale = 1일 때를 기준으로 정규화된 Stage 원점의 좌표. */
  normalizedX: number
  normalizedY: number
}

interface StageEventListening {
  focus: boolean
  translate: boolean
  draw: boolean
}

interface SourceData {
  url: string | symbol
  naturalWidth: number
  naturalHeight: number
}

interface StageStoreParams {
  mode: ObserveMode
  src?: string
  sandboxArea?: Area
}

export interface StageAttributes {
  W: number
  H: number
  scale: number
  origin: StageOrigin
  gutter: GutterInfo
  normalizeFactor: number
}

export interface StageStore {
  mode: ObserveMode
  draggable: boolean
  origin: StageOrigin
  scale: number
  __INTERNAL_DEFERRED__scale: number
  listening: StageEventListening
  node: Konva.Stage
  source: SourceData
  sandboxArea: Area
}

const UNINITIALIZED = Symbol('UNINITIALIZED')

export const useStageStore = ({
  mode,
  src,
  sandboxArea = INITIAL.sandboxArea,
}: StageStoreParams) => {
  const { get, getAll, update, subscribe } = useAtomicStore<StageStore>({
    mode,
    draggable: true,
    origin: { x: 0, y: 0, normalizedX: 0, normalizedY: 0 },
    scale: 1,
    __INTERNAL_DEFERRED__scale: 1,
    listening: { focus: true, translate: true, draw: true },
    /** TODO:ksh: undefined 말고 {}로 둬도 문제 없는지 충분한 테스트 필요. - 2024.07.15 */
    node: {} as Konva.Stage,
    source: { url: src || UNINITIALIZED, naturalWidth: 0, naturalHeight: 0 },
    sandboxArea,
  })

  const warnUninitialized = useCallback(() => {
    if (!get((s) => s.node) && process.env.NODE_ENV !== 'production') {
      throw new Error('Stage node is not set. Make sure to register stage node first before using this method.')
    }
  }, [])

  const node = useCallback((node) => {
    if (node === undefined) {
      return get((s) => s.node)
    }
    const prevNode = get((s) => s.node)

    if (!node || prevNode === node) {
      return
    }
    update({ node })
  }, []) as GetSet<Konva.Stage, [node: Konva.Stage | null]>

  const isSandbox = useCallback(() => get((s) => s.mode) === 'sandbox', [])

  const draggable = useCallback((value?: boolean) => {
    warnUninitialized()
    if (value === undefined) {
      return get((s) => s.draggable)
    }
    update({ draggable: value })
  }, []) as GetSet<boolean>

  const origin = useCallback((x?: number, y?: number, scale?: number) => {
    warnUninitialized()
    if (x === undefined || y === undefined || scale === undefined) {
      return get((s) => s.origin)
    }
    const normalizedX = x / scale
    const normalizedY = y / scale

    update({ origin: { x, y, normalizedX, normalizedY } })
  }, []) as GetSet<StageOrigin, [x: number, y: number, scale: number]>

  const scale = useCallback((scale?: number) => {
    if (scale === undefined) {
      return get((s) => s.scale)
    }
    update({ scale })
  }, []) as GetSet<number>

  const deferredScale = useCallback((scale: number) => {
    update({ __INTERNAL_DEFERRED__scale: scale })
  }, [])

  const getAttributes = useCallback((): StageAttributes => {
    warnUninitialized()

    const { width, height, gutter, origin, scaleX, normalizeFactor } = get((s) => s.node)?.getAttrs() ?? {}

    return {
      W: width - gutter.w,
      H: height - gutter.h,
      scale: scaleX,
      gutter,
      origin,
      normalizeFactor,
    }
  }, [])

  const setViewAttributes = useCallback((origin: Point2D, scale: number) => {
    warnUninitialized()

    const [x, y] = origin
    const normalizedX = x / scale
    const normalizedY = y / scale

    update({ origin: { x, y, normalizedX, normalizedY }, scale })
  }, [])

  const sourceMeta = useCallback((meta: Partial<SourceData>) => {
    update((p) => ({ source: { ...p.source, ...meta } }))
  }, [])

  const listenFocus = useCallback((listening?: boolean) => {
    if (listening === undefined) {
      return get((s) => s.listening.focus)
    }
    update((p) => ({ listening: { ...p.listening, focus: listening } }))
  }, []) as GetSet<boolean>

  const listenTranslate = useCallback((listening?: boolean) => {
    if (listening === undefined) {
      return get((s) => s.listening.translate)
    }
    update((p) => ({ listening: { ...p.listening, translate: listening } }))
  }, []) as GetSet<boolean>

  const listenDraw = useCallback((listening?: boolean) => {
    if (listening === undefined) {
      return get((s) => s.listening.draw)
    }
    update((p) => ({ listening: { ...p.listening, draw: listening } }))
  }, []) as GetSet<boolean>

  useEffect(() => {
    if (src) {
      update((p) => ({ source: { ...p.source, url: src } }))
    }
  }, [src])

  useEffect(() => {
    if (sandboxArea.W && sandboxArea.H) {
      update({ sandboxArea })
    }
  }, [sandboxArea.W, sandboxArea.H])

  return {
    get,
    getAll,
    node,
    isSandbox,
    subscribe,
    draggable,
    origin,
    scale,
    deferredScale,
    getAttributes,
    setViewAttributes,
    sourceMeta,
    listening: {
      focus: listenFocus,
      translate: listenTranslate,
      draw: listenDraw,
    },
  }
}
