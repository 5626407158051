import { ValueOf } from '@ahha/utils/@types/typeUtils'
import { ComboKeys, NormalKeys } from '@ahha/utils/hooks/Accessibility/useHotKeys'
import { Node, FileMeta, CommonListPayload } from '@/api/DatasetNode/types'
import { ModelValueType } from '@/pages/const'
import { UserInfo } from '@/api/User/types'
import { Paginated } from '@/api/Base/types'
import { OS_TYPE } from '@/pages/Labeling/components/ShortcutDrawer/hotKeys'
import { LABEL_FILE_STATUS, LABELING_NODE_STATUS } from './const'

export type LabelingNodeBase = {
  confirmedAt: string | null,
  labelingFinishedAt: string | null,
  modelType: ModelValueType | null,
  datasetNodeIds: string[],
  labelerId?: string
  labelingMembers: { userId: string, items: number, name: string, profileImageAccessibleUrl?: string }[] | null,
  reviewers: { userId: string, name?: string, profileImageAccessibleUrl?: string }[],
  numOfDatasetItems: number,
  numOfLabelingItems: number,
  numOfCompletedItems: number,
  numOfFinishedItems: number,
  numOfNotFinishedItems: number,
  numOfNotReviewedItems: number,
  numOfRejectedItems: number,
  status: LabelingFileStatusType,
  labelingNodeStatus: LabelingNodeStatusType,
  classGroupId: string,
  classGroupName: string,
  isTempClassGroup?: boolean,
}

export type LabelingNodeInfo = Node<LabelingNodeBase> & LabelingNodeBase

export type LabelingFileStatusType = ValueOf<typeof LABEL_FILE_STATUS>

export type LabelingNodeStatusType = ValueOf<typeof LABELING_NODE_STATUS>

export type LabelingClass = {
  createdBy: string,
  updatedBy: string | null,
  createdAt: string,
  updatedAt: string | null,
  _id: string,
  labelingNodeId?: string, // main node
  name: string,
  color: string,
  metadata: any,
  order: number | null,
}

export type Comment = {
  commentId: string,
  commentedBy: string,
  comment: string,
  commentedAt: Date,
  name: string
}

export type LabelingObject = {
  _id: string,
  labelingItemId: string,
  zOrder: number,
  isPathEditable: boolean,
  isFixedRatio: boolean,
  region: any,
  metadata: any,
  text: string,
  labelingClass: LabelingClass,
  type: ObjectToolValueType,
  isConfirm: boolean | null,
  reviewedBy: unknown,
  comments: Comment[] | null,
  createdAt: Date,
  updatedAt: Date | null,
  createdBy: string,
  updatedBy: string | null,
}

export type LabelingNodeItem = {
  createdBy: string,
  updatedBy: string | null,
  createdAt: string,
  updatedAt: string | null,
  _id: string,
  labelingNodeId: string,
  datasetItemId: string,
  labelerId: string,
  status: LabelingFileStatusType,
  fileMeta: FileMeta,
  labeler: UserInfo,
  accessibleUrl: string,
  previewAccessibleUrl: string,
  name: string,
  numOfLabelObjects: number,
  labelingObjects: LabelingObject[],
}

export type UserShortcut = {
  action: string,
  shortcutKey: NormalKeys | ComboKeys,
  type: ValueOf<typeof OS_TYPE>,
}

/** Response */
export type LabelingCommonResponse = {
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
  _id: string,
  userId: string,
}

export type GetLabelingNodeItemsPayload = CommonListPayload & {
  status?: string
  labelers?: string
  labelingFilter?: string
  reviewFilter?: string
  search?: string
}

export type GetLabelingNodeItemsResponse = Paginated<LabelingNodeItem[]>

export type GetUserMetaResponse = UpdateUserMetaPayload & LabelingCommonResponse

export type GetUserShortcutsResponse = {
  shortCutKeySettings: UserShortcut[],
} & LabelingCommonResponse

export type CreateLabelingNodePayload = {
  projectId: string
  datasetNodeId?: string
  name?: string
  metadata?: any
}

/** Payload */
export type UpdateLabelingNodeSettingsPayload = {
  labelingNodeId: string
  labelerSetting: { userId: string, items: number }[]
  modeltype: ModelValueType // 오타x
  metadata?: any
}

export type UpdateAssignedLabelersPayload = {
  labelingNodeId: string
  updatedItems: { labelerId: string, labelingItemId: string }[]
}

export type UpdateLabelingNodePayload = {
  id: string
  datasetNodeId?: string
  name?: string
  area?: string
  metadata?: any
}

export type AddClassGroupReferencePayload = {
  labelingNodeId: string
  classGroupId: string
}

export const ObjectToolType = {
  RECTANGLE: 'RECTANGLE',
  ELLIPSE: 'ELLIPSE',
  POLYGON: 'POLYGON',
  TAG: 'TAG',
  COMMENT: 'COMMENT',
} as const

export type ObjectToolValueType = ValueOf<typeof ObjectToolType>

export type SaveObjectsPayload = {
  labelingItemId: string,
  labelingObjects: {
    isFixedRatio?: boolean,
    isPathEditable?: boolean,
    labelingClassId: string,
    labelingObjectId?: string,
    metadata?: any,
    region?: any,
    text?: string,
    type: ObjectToolValueType,
    zOrder: number,
  }[],
}

export type CreateObjectPayload = {
  labelingItemId: string,
  labelingClassId: string,
  type: ObjectToolValueType,
  zOrder: number,
  isPathEditable?: boolean,
  isFixedRatio?: boolean,
  region?: any,
  metadata?: any,
  text?: string,
}

export type UpdateObjectPayload = Omit<CreateObjectPayload, 'labelingItemId'> & {
  labelingObjectId: string
}

export type GetMagicWandObjectPayload = {
  labelingItemId: string
  x: number,
  y: number,
}

export type GetMagicWandObjectResponse = {
  result: {
    region: any
    type: 'POLYGON' | 'RECTANGLE'
  }[],
  input: {
    image: string
    point: [number, number]
  }
}

export type UpdateUserMetaPayload = {
  labelingNodeId: string,
  duration: number,
  lastIndex: number,
  lastLabelingItemId: string,
}

export type UpdateUserShortcutsPayload = {
  shortcutKeySettings: UserShortcut[]
}

export interface CreateCommentPayload {
  comment: string
  fileId: string
  metadata?: any
  region: Record<string, any>
}

export interface AddCommentPayload {
  id: string
  comment: string
}

export interface UndoRedoResponse {
  currentObject: LabelingObject | null
  labelingHistory : {
    action: 'CREATE' | 'UPDATE' | 'DELETE'
    backupObject: LabelingObject
    createdAt: string
    createdBy: string | null
    isDone: boolean
    labelingItemId: string
    undo: boolean
    updatedAt: string | null
    updatedBy: string | null
    userId: string
    _id: string
  }
}

/* CLASSIFICATION */
export type GetClassificationItemsPayload = Omit<CommonListPayload, 'keyword'> & {
  labelingNodeId: string
  filters?: string
  classes?: string
}

export type GetClassificationItemsResponse = Paginated<LabelingNodeItem[]>

export type CreateTagObjectPayload = {
  labelingItemId: string,
  labelingClassId: string,
  zOrder?: number,
  metadata?: any,
}

export type CreateTagToImagesPayload = {
  labelingNodeId: string,
  taggedItems: { labelingClassId: string, labelingItemId: string }[],
  finishAllTaggedItems: boolean;
}

/* Review Node */
export type ReviewNodeBase = LabelingNodeBase & {
  labelingNodeId?: string
}

export type ReviewNodeInfo = Node<ReviewNodeBase> & ReviewNodeBase

export type CreateReviewNodePayload = {
  projectId: string
  labelingNodeId?: string
  name?: string
  metadata?: any
}

export type UpdateReviewNodePayload = {
  reviewNodeId: string
  metadata?: any
  name?: string
  labelingNodeId?: string
  reviewerIds?: string[]
}

export type GetAllDatasetsForReviewResponse = {
  reviewDatasets: ReviewNodeInfo[]
  labelingDatasets: LabelingNodeInfo[]
}

export type StartReviewNodePayload = {
  reviewNodeId: string,
  labelingNodeId: string,
}
