import { Edge, MarkerType, Node, Node as ReactFlowNode } from '@xyflow/react'
import { every, filter, find, forEach, get, has, isEmpty, map } from 'lodash'

import { useI18n } from '@ahha/i18n/i18n'

import { LabelingNodeInfo, ReviewNodeInfo } from '@/api/LabelingNode/types'
import { ModelNodeInfo } from '@/api/ModelNode/types'

import { GenericNode, NodeTypes } from '@/pages/RenewedPipeline/Nodes/types'
import { ModelTypes, ModelValueType, TaskStatusTypes } from '@/pages/const'
import { LABELING_COMPLETED_ITEMS } from '@/pages/Labeling/const'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'
import { ValidationNodeInfo } from '@/api/ValidationNode/types'
import { InferenceNodeInfo } from '@/api/InferenceNode/types'
import { DatasetNodeInfo } from '@/api/DatasetNode/types'
import { ImageMatchingNodeInfo } from '@/api/ImageMatchingNode/types'
import { PreprocessingNodeInfo } from '@/api/PreprocessingNode/types'
import { PreprocessingResultNodeInfo } from '@/api/PreprocessingResultNode/types'

export const getModelTypeText = (modelType: ModelValueType | null | undefined) => {
  const { t } = useI18n()
  if (modelType === ModelTypes.ANOMALY_DETECTION) {
    return t('common_anomalyDetection')
  }
  if (modelType === ModelTypes.SEGMENTATION) {
    return t('common_segmentation')
  }
  if (modelType === ModelTypes.CLASSIFICATION) {
    return t('common_classification')
  }
  if (modelType === ModelTypes.OBJECT_DETECTION) {
    return t('common_objectDetection')
  }
  return '-'
}

export const transformNodes = (type: NodeTypes, nodes: (GenericNode | undefined)[]): ReactFlowNode[] => {
  const filtered = filter(nodes, (n) => n !== undefined) as GenericNode[]
  return map(filtered, (n) => ({
    id: n._id,
    type,
    position: get(n, 'metadata.position', { x: 0, y: 0 }),
    data: n,
  }))
}

export const extractAllEdges = (nodes: Node[]) => {
  const edges: Edge[] = []
  forEach(nodes, (n, i) => {
    if (isPreprocessingNode(n)) {
      const { datasetNodeIds, metadata } = n.data
      edges.push(...map(datasetNodeIds, (d) => ({ id: `${d}-${n.id}-${i}`, source: d, target: n.id, type: 'customEdge', data: { points: metadata.points }, markerEnd: { type: MarkerType.Arrow } })))
    }
    if (isLabelingNode(n)) {
      const { datasetNodeIds, metadata } = n.data
      edges.push(...map(datasetNodeIds, (d) => ({ id: `${d}-${n.id}-${i}`, source: d, target: n.id, type: 'customEdge', data: { points: metadata.points }, markerEnd: { type: MarkerType.Arrow } })))
    }
    if (isReviewNode(n)) {
      const { labelingNodeId, metadata } = n.data
      if (labelingNodeId) {
        edges.push({ id: `${labelingNodeId}-${n.id}`, source: labelingNodeId, target: n.id, type: 'customEdge', data: { points: metadata.points }, markerEnd: { type: MarkerType.Arrow } })
      }
    }
    if (isImageMatchingNode(n)) {
      const { datasetNodeIds, metadata } = n.data
      if (datasetNodeIds) {
        edges.push(...map(datasetNodeIds, (d) => ({
          id: `${d}-${n.id}-${i}`,
          source: d,
          target: n.id,
          type: 'customEdge',
          data: { points: filter(metadata.points, (p) => p.id.includes(d)) },
          markerEnd: { type: MarkerType.Arrow },
        })))
      }
    }
    if (isModelNode(n)) {
      const { labelingNodeIds, metadata } = n.data
      edges.push(...map(labelingNodeIds, (d) => ({ id: `${d}-${n.id}-${i}`, source: d, target: n.id, type: 'customEdge', data: { points: metadata.points }, markerEnd: { type: MarkerType.Arrow } })))
    }
    if (isValidationNode(n)) {
      const { modelNodeId, labelingNodeId, metadata } = n.data
      if (labelingNodeId) {
        edges.push({ id: `${labelingNodeId}-${n.id}-labeling`,
          source: labelingNodeId,
          target: n.id,
          targetHandle: 'labeling',
          type: 'customEdge',
          data: { points: filter(metadata.points, (p) => p.id.includes(labelingNodeId)) },
          markerEnd: { type: MarkerType.Arrow },
        })
      }
      if (modelNodeId) {
        edges.push({ id: `${modelNodeId}-${n.id}-model`,
          source: modelNodeId,
          target: n.id,
          targetHandle: 'train',
          type: 'customEdge',
          data: { points: filter(metadata.points, (p) => p.id.includes(modelNodeId)) },
          markerEnd: { type: MarkerType.Arrow },
        })
      }
    }
    if (isInferenceNode(n)) {
      const { datasetNodeId, modelNodeId, metadata } = n.data
      if (datasetNodeId) {
        edges.push({
          id: `${datasetNodeId}-${n.id}-dataset`,
          source: datasetNodeId,
          target: n.id,
          targetHandle: 'dataset',
          type: 'customEdge',
          data: { points: filter(metadata.points, (p) => p.id.includes(datasetNodeId)) },
          markerEnd: { type: MarkerType.Arrow },
        })
      }
      if (modelNodeId) {
        edges.push({ id: `${modelNodeId}-${n.id}-model`,
          source: modelNodeId,
          target: n.id,
          targetHandle: 'train',
          type: 'customEdge',
          data: { points: filter(metadata.points, (p) => p.id.includes(modelNodeId)) },
          markerEnd: { type: MarkerType.Arrow },
        })
      }
    }
    if (isPreprocessingResultNode(n)) {
      const { preprocessingNodeId, metadata } = n.data
      if (preprocessingNodeId) {
        edges.push({ id: `${preprocessingNodeId}-${n.id}`, source: preprocessingNodeId, target: n.id, type: 'customEdge', data: { points: metadata.points }, markerEnd: { type: MarkerType.Arrow } })
      }
    }
  })
  return edges
}

export const validateHandleTypes = (sourceHandle: string, targetHandle: string) => {
  if (sourceHandle === 'imageMatching') {
    return targetHandle === 'labeling'
  }
  return sourceHandle === targetHandle
}

/** returns true if valid, object with error title & message if invalid */
export const validateNodeConnection = (sourceNodeId: string, targetNodeType: NodeTypes, nodeList: Node[], targetNodeId?: string) => {
  const sourceNode = find(nodeList, { id: sourceNodeId })
  const targetNode = find(nodeList, { id: targetNodeId }) // 1:1 연결민 가능한 노드들
  if (isDatasetNode(sourceNode)) {
    const { data } = sourceNode
    if (!data.isActive) {
      return {
        title: 'pipeline_error_nodeLink_title',
        message: 'pipeline_error_nodeLink_datasetSetupRequired',
      }
    }
    // labeling node:datasetNode 1:1
    if (targetNodeType === 'labeling' && targetNode) {
      const { data } = targetNode
      if (!isEmpty(data.datasetNodeIds)) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
    if (targetNodeType === 'inference' && targetNode) {
      const { data } = targetNode
      if (data.datasetNodeId) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
  }
  if (isImageMatchingNode(sourceNode)) {
    const { data } = sourceNode
    if (data.matchedCount === 0) {
      return {
        title: '',
        message: '매칭을 먼저 진행해주세요.',
      }
    }
    if (isModelNode(targetNode) && targetNode) {
      const { data } = targetNode
      if (!isEmpty(data.labelingNodeIds)) { // FIXME: 이미 연결된 이미지 매칭 노드가 있는지 확인 필요!
        return {
          title: '',
          message: '모델 노드에 이미 연결된 노드가 있습니다.',
        }
      }
    }
  }
  if (isLabelingNode(sourceNode)) {
    const { data } = sourceNode
    if (targetNodeType === 'review') {
      if (isEmpty(data.labelingMembers)) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_cannotLinkToReview',
        }
      }
    } else if (!(LABELING_COMPLETED_ITEMS.includes(data.status) || (has(data, 'subNodes') && every(data.subNodes, (s) => s.status === LABEL_FILE_STATUS.FINISHED)))) {
      return {
        title: 'pipeline_error_nodeLink_title',
        message: 'pipeline_error_nodeLink_notCompletedLabeling',
      }
    } else if (targetNodeType === 'train' && targetNode) {
      const { data } = targetNode
      if (!isEmpty(data.labelingNodeIds)) { // FIXME: 이미 연결된 이미지 매칭 노드가 있는지 확인 필요!
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    } else if (targetNodeType === 'validation' && targetNode) {
      const { data } = targetNode
      if (data.labelingNodeId) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
  }
  if (isReviewNode(sourceNode)) {
    const { data } = sourceNode
    if (data.status !== LABEL_FILE_STATUS.COMPLETED) {
      return {
        title: 'pipeline_error_nodeLink_title',
        message: 'pipeline_error_nodeLink_notCompletedReview',
      }
    }
    // model노드 labeling node 1:1
    if (targetNodeType === 'train' && targetNode) {
      const { data } = targetNode
      if (!isEmpty(data.labelingNodeIds)) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
    if (targetNodeType === 'validation' && targetNode) {
      const { data } = targetNode
      if (data.labelingNodeId) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
  }
  if (isModelNode(sourceNode)) {
    const { data } = sourceNode
    if (data.status !== TaskStatusTypes.COMPLETED) {
      return {
        title: 'pipeline_error_nodeLink_title',
        message: 'pipeline_error_nodeLink_notCompletedTraining',
      }
    }
    if ((targetNodeType === 'validation' || targetNodeType === 'inference') && targetNode) {
      const { data } = targetNode
      if (data.modelNodeId) {
        return {
          title: 'pipeline_error_nodeLink_title',
          message: 'pipeline_error_nodeLink_nodeConnected',
        }
      }
    }
  }
  return true
}

export const isDatasetNode = (node?: Record<string, any>): node is Node<DatasetNodeInfo, 'dataset'> => node?.type === 'dataset'
export const isLabelingNode = (node?: Record<string, any>): node is Node<LabelingNodeInfo, 'labeling'> => node?.type === 'labeling'
export const isReviewNode = (node?: Record<string, any>): node is Node<ReviewNodeInfo, 'review'> => node?.type === 'review'
export const isModelNode = (node?: Record<string, any>): node is Node<ModelNodeInfo, 'train'> => node?.type === 'train'
export const isValidationNode = (node?: Record<string, any>): node is Node<ValidationNodeInfo, 'validation'> => node?.type === 'validation'
export const isInferenceNode = (node?: Record<string, any>): node is Node<InferenceNodeInfo, 'inference'> => node?.type === 'inference'
export const isImageMatchingNode = (node?: Record<string, any>): node is Node<ImageMatchingNodeInfo, 'imageMatching'> => node?.type === 'imageMatching'
export const isPreprocessingNode = (node?: Record<string, any>): node is Node<PreprocessingNodeInfo, 'preprocessing'> => node?.type === 'preprocessing'
export const isPreprocessingResultNode = (node?: Record<string, any>): node is Node<PreprocessingResultNodeInfo, 'preprocessingResult'> => node?.type === 'preprocessingResult'
