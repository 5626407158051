import { Point2D, Vector2D } from '@ahha/stableComponents/Canvas/types/point'

/** 점 p1에서 점 p2로 향하는 단위 벡터를 계산 */
export const getUnitVector = (p1: Point2D, p2: Point2D): Vector2D => {
  const [x1, y1] = p1
  const [x2, y2] = p2
  const L = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)

  return [(x1 - x2) / L, (y1 - y2) / L]
}

/** 점 p1에서 점 p2로 향하는 벡터에 대해 수직인 벡터를 계산 */
export const getOrthogonalUnitVector = (p1: Point2D, p2: Point2D): Vector2D => {
  const [x, y] = getUnitVector(p1, p2)

  return [-1 * y, x]
}

/**
 * @param vector2D 벡터의 x, y 성분
 * @returns 해당 벡터가 이루는 각도(degree). +x축을 기준으로 시계 방향이 양수.
 */
export const getVectorAngle = (x: number, y: number) => Math.atan2(y, x) * (180 / Math.PI)

export const calculateDotProduct = ([x1, y1]: Vector2D, [x2, y2]: Vector2D) => {
  const L1 = Math.sqrt(x1 ** 2 + y1 ** 2)
  const L2 = Math.sqrt(x2 ** 2 + y2 ** 2)

  return (x1 * x2 + y1 * y2) / (L1 * L2)
}

export const getDimensionSize = (p1: Point2D, p2: Point2D) => {
  const [x1, y1] = p1
  const [x2, y2] = p2

  return {
    width: Math.abs(x2 - x1),
    height: Math.abs(y2 - y1),
  }
}

/**
 * 주어진 벡터를 `rotation`만큼 회전변환한 벡터를 계산
 * @param x 벡터의 x 성분
 * @param y 벡터의 y 성분
 * @param rotation 벡터의 회전각(degree)
 * @returns
 */
export const rotate = (x: number, y: number, rotation = 0) => {
  const rad = rotation * (Math.PI / 180)

  return {
    x: x * Math.cos(rad) - y * Math.sin(rad),
    y: x * Math.sin(rad) + y * Math.cos(rad),
  }
}
