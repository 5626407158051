import { Node } from '@xyflow/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { filter, find, forEach, keyBy, map, set } from 'lodash'

import { ClientTaskTypes, INITIAL_STATE, PipelineState } from '@/stores/slices/RenewedPipeline/const'
import { GenericNode, NodeTypes } from '@/pages/RenewedPipeline/Nodes/types'
import {
  isInferenceNode,
  isModelNode,
  isPreprocessingResultNode,
  isValidationNode,
  transformNodes
} from '@/pages/RenewedPipeline/Nodes/utils'
import { ModelTypes, ModelValueType, RUNNING_TASKS, TaskStatusValueType } from '@/pages/const'

export const pipelineSlice = createSlice({
  name: 'renewedPipeline',
  initialState: INITIAL_STATE,
  reducers: {
    resetPipelineState: () => INITIAL_STATE,
    addPlaceholderNode: (state, action: PayloadAction<PipelineState['placeholder']>) => {
      state.placeholder = action.payload || { position: { x: 0, y: 0 } }
    },
    // placeholder를 통해 새로운 노드 생성 후 삭제인 경우 생성된 노드를 타입과 함께 payload에 첨부
    removePlaceholderNode: (state, action: PayloadAction<{ type: NodeTypes, nodeInfo: GenericNode } | undefined>) => {
      if (action.payload) {
        const { type, nodeInfo } = action.payload
        state.nodes = [...state.nodes, ...transformNodes(type, [nodeInfo])]
      }
      state.placeholder = undefined
    },
    updateNodes: (state, action: PayloadAction<Node[]>) => {
      const previousNodesById = keyBy(state.nodes, 'id')
      forEach(action.payload, (n) => set(previousNodesById, n.id, n))
      state.nodes = map(previousNodesById, (val, key) => val)
    },
    removeNodes: (state, action: PayloadAction<string[]>) => {
      state.nodes = filter(state.nodes, (n) => !action.payload.includes(n.id))
    },
    // 노드 내의 data 객체만 업데이트 (노드 타입과 상관 없이 노드 정보 get 해온 응답값 통채로 넘겨주면 됨)
    updateNodeData: (state, action: PayloadAction<GenericNode>) => {
      state.nodes = map(state.nodes, (n) => (n.id === action.payload._id ? { ...n, data: { ...n.data, ...action.payload } } : n))
    },

    /* TASKS */
    addNodeInProgress: (state, action: PayloadAction<{ type: ClientTaskTypes, nodeId: string, modelType?: ModelValueType }>) => {
      if (!find(state.tasksInProgress, (t) => t.nodeId === action.payload.nodeId)) {
        state.tasksInProgress = [...state.tasksInProgress, action.payload]
      }
    },
    removeNodeInProgress: (state, action: PayloadAction<string>) => {
      state.tasksInProgress = filter(state.tasksInProgress, (t) => t.nodeId !== action.payload)
    },
    updateNodeTaskStatus: (state, action: PayloadAction<{nodeId: string, status: TaskStatusValueType}>) => {
      const { nodeId, status } = action.payload
      state.nodes = map(state.nodes, (n) => (n.id === nodeId ? { ...n, data: { ...n.data, status } } : n))
    },
    resetNodeInProgress: (state, action: PayloadAction<void>) => {
      state.tasksInProgress = []
    },
    updateInProgressTasks: (state, action: PayloadAction<Node[]>) => {
      const nodes = action.payload
      forEach(nodes, (n) => {
        if (!find(state.tasksInProgress, (t) => t.nodeId === n.id)) {
          if (isModelNode(n) && n.data.status && RUNNING_TASKS.includes(n.data.status)) {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'train', nodeId: n.id, modelType: n.data.modelType || ModelTypes.ANOMALY_DETECTION }]
          }
          if (isValidationNode(n) && n.data.status && RUNNING_TASKS.includes(n.data.status)) {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'validation', nodeId: n.id }]
          }
          if (isInferenceNode(n) && n.data.status && RUNNING_TASKS.includes(n.data.status)) {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'inference', nodeId: n.id }]
          }
          if (isPreprocessingResultNode(n) && n.data.status && RUNNING_TASKS.includes(n.data.status)) {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'preprocessingResult', nodeId: n.id }]
          }
        }
      })
    },
  },
})

export const {
  resetPipelineState,
  addPlaceholderNode,
  removePlaceholderNode,
  updateNodes,
  removeNodes,
  updateNodeData,

  addNodeInProgress,
  removeNodeInProgress,
  updateNodeTaskStatus,
  resetNodeInProgress,
  updateInProgressTasks,
} = pipelineSlice.actions

const pipelineReducers = pipelineSlice.reducer

export default pipelineReducers
