import { createAsyncThunk } from '@reduxjs/toolkit'
import { some } from 'lodash'

import { API } from '@/api'
import { ThunkConfig } from '@/stores/slices/Labeling/types'
import { fetchQueryData } from '@/stores/slices/Labeling/queryData'

export const initializeProject = async (labelingNodeId: string, currentUserId: string, start?: number) => {
  const FILES_PER_PAGE = 5
  const { lastIndex } = await API().Labeling.getUserMeta(labelingNodeId)
  const startIndex = lastIndex ?? 0 // TODO: 더 나은 로직을 고려해서 수정
  const endIndex = startIndex + (FILES_PER_PAGE - 1)
  const currentPage = Math.floor(startIndex / FILES_PER_PAGE)

  const pageStart = currentPage * FILES_PER_PAGE
  const pageEnd = pageStart + (FILES_PER_PAGE - 1)

  const [annotation, files] = await Promise.all([
    fetchQueryData.overview(labelingNodeId),
    fetchQueryData.initFiles(labelingNodeId, startIndex),
  ])

  const itemFiles = await Promise.all(
    files.list.map((d) => fetchQueryData.item(labelingNodeId, d._id))
  )
  const partialDocs = Array.from(
    { length: annotation.numOfLabelingItems ?? annotation.numOfDatasetItems }, // NOTE: num of labeling items는 없을수도 있어서 항상 datasetItems 갯수로 체크
    (_, i) => (i >= pageStart && i <= pageEnd ? itemFiles[i - pageStart] : null)
  )

  const { datasetNodeIds } = annotation
  const datasetInfo = await fetchQueryData.dataset(annotation.projectId, datasetNodeIds[0])
  const userShortcuts = await API().Labeling.getUserShortcuts()

  return {
    labelingNodeId,
    annotation,
    files: partialDocs,
    start: pageStart,
    end: pageEnd,
    datasetMeta: {
      datasetNodeId: datasetInfo._id,
      name: datasetInfo.name,
    },
    userMeta: { lastIndex },
    userShortcuts,
    userIsReviewer: some(annotation.reviewers, { userId: currentUserId }),
  }
}

export const loadLabelingData = createAsyncThunk<Awaited<ReturnType<typeof initializeProject>>, string, ThunkConfig>(
  'labeling/load-labeling-data',
  async (labelingNodeId: string, thunkAPI) => {
    const initialIndex = thunkAPI.getState().labeling.currentIndex
    const currentUserId = thunkAPI.getState().user.user._id
    return initializeProject(labelingNodeId, currentUserId, initialIndex)
  }
)

export const initializeClasses = createAsyncThunk<any, string, ThunkConfig>(
  'labeling/initialize-classes',
  async (labelingNodeId: string, thunkAPI) => {
    const { data: classes } = await API().Labeling.getUserClasses(labelingNodeId)
    return classes
  }
)

export const initializeClassification = async (labelingNodeId: string, start?: number) => {
  const FILES_PER_PAGE = 5
  const startIndex = start ?? 0
  const endIndex = startIndex + (FILES_PER_PAGE - 1)
  const currentPage = Math.floor(startIndex / FILES_PER_PAGE)

  const pageStart = currentPage * FILES_PER_PAGE
  const pageEnd = pageStart + (FILES_PER_PAGE - 1)

  const [annotation, files] = await Promise.all([
    fetchQueryData.overview(labelingNodeId),
    fetchQueryData.initFiles(labelingNodeId, startIndex),
  ])

  const partialDocs = Array.from(
    { length: annotation.numOfLabelingItems },
    // (_, i) => (i >= pageStart && i <= pageEnd ? objects[i - pageStart] : null)
    (_, i) => (i >= pageStart && i <= pageEnd ? files.list[i - pageStart] : null)
  )

  const { datasetNodeIds } = annotation
  const datasetInfo = await fetchQueryData.dataset(annotation.projectId, datasetNodeIds[0])

  return {
    labelingNodeId,
    annotation,
    files: partialDocs,
    start: pageStart,
    end: pageEnd,
    datasetMeta: {
      datasetNodeId: datasetInfo._id,
      name: datasetInfo.name,
    },
  }
}

export const loadClassification = createAsyncThunk(
  'labeling/load-classification',
  async (labelingNodeId: string) => initializeClassification(labelingNodeId)
)
