import { LabelingState } from '@/stores/slices/Labeling/types'
import { ToolType } from '@/pages/Labeling/types'
import { DEFAULT_SHORTCUTS } from '@/pages/Labeling/components/ShortcutDrawer/hotKeys'

export const ANNOTATION_GLOBAL_OPACITY = 0.8

export const initialState: LabelingState = {
  parentNodeId: '',
  labelingNodeId: '',
  projectId: '',
  classGroupId: '',
  name: '',
  modelType: 'ANOMALY_DETECTION',
  tool: {
    tool: ToolType.POINTER,
    class: null,
    shape: ToolType.RECTANGLE,
    brush: 1,
    isBrushAutoFill: false,
    opacity: ANNOTATION_GLOBAL_OPACITY * 100,
    magicWandThreshold: 16,
    rgb: { all: true, red: true, green: true, blue: true },
    isTracking: false,
  },
  fileFilter: [],
  originalFiles: [],
  files: [],
  isInProgress: false,
  annotation: {},
  comments: {},
  /** TODO:ksh: 파일에 필터가 걸려있을 때 index 관리 방법 - 2024.07.30 */
  currentIndex: 0,
  isRoiAllowed: false,
  classes: [],
  datasetInfo: {
    datasetNodeId: '',
    name: '',
  },
  userShortcuts: DEFAULT_SHORTCUTS,
  userIsReviewer: false,
  initialNodeStatus: 'NOT_STARTED',
  reviewers: [],
  userCursorColor: '',
  numOfLabelingData: 0,
}

export const PossibleShape = [
  ToolType.RECTANGLE,
  ToolType.ELLIPSE,
]

export const PossibleTool = [
  ...PossibleShape,
  ToolType.POINTER,
  ToolType.POLYGON,
  ToolType.BRUSH,
  ToolType.MAGICWAND,
  ToolType.AUTOPIXEL,
  ToolType.COMMENT,
  'select',
]

export const MAX_SNAPSHOT = 50

export const CURSOR_COLORS = ['#3A4FCC', '#12828E', '#0B6F0D', '#FC6D21', '#DD2C18', '#C41162']
