import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { ListenerMiddlewareInstance, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '@/stores/reducers'

import rootSaga from '@/sagas'
import { listenerMiddleware } from '@/stores/listenerMiddleware'

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppListenerMiddleware = ListenerMiddlewareInstance<RootState, AppDispatch>

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'config'],
  blacklist: ['UI', 'DashboardConfig', 'annotation', 'renewedPipeline', 'labeling'], // TODO: 대시보드 컨피그 새로고침시 유지 시킬지 확인 필요
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    .prepend(listenerMiddleware.middleware)
    .concat(sagaMiddleware),
})

const persistor = persistStore(store)

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const useAppDispatch = () => useDispatch<AppDispatch>()

const useAppStore = () => useStore<RootState>()

sagaMiddleware.run(rootSaga)

export {
  store,
  persistor,
  useAppSelector,
  useAppDispatch,
  useAppStore

}
