import { combineReducers } from 'redux'

import Config from '@/stores/slices/configSlice'
import DashboardConfigReducer from '@/stores/slices/Datacamp/dashboardSlice'
import DashboardPanelReducer from '@/stores/slices/Datacamp/dashboardPanelSlice'
import widgetConfigReducer from '@/stores/slices/Datacamp/widgetSlice'
import queryConfigReducer from '@/stores/slices/Datacamp/querySlice'
// import AuthReducer from '@/stores/slices/authSlice'
import UserReducer from '@/stores/slices/userSlice'

// Lisa slices
import ValidationChartReducer from '@/stores/slices/Lisa/chartSlice'
import cameraReducer from '@/stores/slices/cameraSlice/cameraSlice'
import AnnotationRoi from '@/stores/annotation/ROI/slice'
import LabelingReducer from '@/stores/slices/Labeling/labelingSlice'
import renewedPipelineReducers from '@/stores/slices/RenewedPipeline/pipelineSlice'

const rootReducer = combineReducers({
  dashboardConfig: DashboardConfigReducer,
  dashboardPanel: DashboardPanelReducer,
  widgetConfig: widgetConfigReducer,
  validationChart: ValidationChartReducer,
  camera: cameraReducer,
  // Auth: AuthReducer, // for redux-saga test
  user: UserReducer,
  annotationRoi: AnnotationRoi,
  queryConfig: queryConfigReducer,
  config: Config,
  labeling: LabelingReducer,
  renewedPipeline: renewedPipelineReducers,
})

export type ReducerType = ReturnType<typeof rootReducer>
export default rootReducer
