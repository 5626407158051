import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import { KonvaNodeEvent } from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/types'
import { isReadonlyArray } from '@ahha/utils/@types/typeChecks'

export const getPointerPosition = (e: KonvaEventObject<MouseEvent>) => e.target.getStage()?.getPointerPosition() ?? { x: 0, y: 0 }

export const registerEvent = <T extends Konva.Node>(
  shape: T | null | undefined,
  events: KonvaNodeEvent | readonly KonvaNodeEvent[],
  handler: (e: KonvaEventObject<MouseEvent>) => void
) => {
  if (isReadonlyArray(events)) {
    events.forEach((event) => shape?.on(event, handler))
  } else {
    shape?.on(events, handler)
  }
}

export const registerGlobalEvent = <E extends keyof WindowEventMap>(event: E, handler: (e: WindowEventMap[E]) => void) => {
  window.addEventListener(event, handler)
}

export const unregisterEvent = <T extends Konva.Node>(
  shape: T | null | undefined,
  event: KonvaNodeEvent,
  handler: (e: KonvaEventObject<MouseEvent>) => void
) => {
  shape?.off(event, handler)
}

export const unregisterGlobalEvent = <E extends keyof WindowEventMap>(event: E, handler: (e: WindowEventMap[E]) => void) => {
  window.removeEventListener(event, handler)
}
