import styled from 'styled-components'

import { TOAST_STATES, ToastType } from '@ahha/utils/@types/types'

export const NotificationListWrapper = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  position: fixed;
  z-index: 9999;
  right: ${({ theme }) => theme.variables.pxToRem(20)};
  top: ${({ theme }) => theme.variables.pxToRem(100)};

  .notificationClear {
    animation: noti-slide-out 0.3s ease-in-out forwards;
  }

  @keyframes noti-slide-in {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes noti-slide-out {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`

export const StyledToastItem = styled.li<ToastType>`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  width: ${({ theme }) => theme.variables.pxToRem(320)};
  padding: 0.625rem;
  border: ${({ theme }) => `1px solid ${theme.colors.border.border4}`};
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.colors.page.primary};
  color: ${({ theme }) => theme.colors.text.primary};
  animation: noti-slide-in 0.3s ease-in-out forwards;

  > .toast__status-color-bar{
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${({ status, theme }) => {
    if (status === TOAST_STATES.ERROR) {
      return theme.colors.bg.error
    }
    if (status === TOAST_STATES.WARNING) {
      return theme.colors.bg.warning
    }
    if (status === TOAST_STATES.SUCCESS) {
      return theme.colors.bg.success
    }
    if (status === TOAST_STATES.INFO) {
      return theme.colors.bg.info
    }
    if (status === TOAST_STATES.DEFAULT) {
      return theme.colors.bg.primary
    }
    return theme.colors.bg.info
  }};
}

  > svg {
    width: 1rem;
    height: 1rem;
  }

  > .toast__text-content-box{
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h5 {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    > .toast__custom-button-box{
        display: flex;
        gap: 0.5rem;
        margin-top: 0.25rem;
    }
  }

  > button {
    > svg {
      align-self: flex-start !important;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
`
