import { RefObject, useEffect, useRef } from 'react'
import { mapValues } from 'lodash'
import Konva from 'konva'

import { isFunction, isNumber, isPlainObject } from '@ahha/utils/@types/typeChecks'
import { isBounded } from '@ahha/stableComponents/Canvas/utils/common'
import { useCanvasViewport } from '@ahha/stableComponents/Canvas/utils/Context/Viewport/useCanvasViewport'
import { useStage } from '@ahha/stableComponents/Canvas/utils/Context/Stage/useStage'

interface BoundRect {
  x: number
  y: number
  width: number
  height: number
}

type Key = string | symbol

type SnapshotId<K extends Key> = 'DEFAULT' | K

type SnapshotUpdater<D> = (current: D, prev: D, defaultSnapshot: D) => D

type ShapeDataGetter<S, D> = (node: S | null) => D | undefined

interface ShapeSnapshotParams<S, K extends Key, D> {
  node: RefObject<S>
  initialSnapshot?: (data: D) => Record<SnapshotId<K>, D>
  getShapeData: ShapeDataGetter<S, D>
  getBoundingRect: (data: D) => BoundRect
}

export const DEFAULT_SNAPSHOT_ID = 'DEFAULT'

const MIN_POSITION = { x: 0.1, y: 0.1 }

const NOT_SCALABLE_PROPERTIES = ['rotation']

export const useShapeSnapshot = <S extends Konva.Node, D, K extends Key = 'DEFAULT'>({
  node,
  initialSnapshot,
  getShapeData,
  getBoundingRect,
}: ShapeSnapshotParams<S, K, D>) => {
  const { getAttributes } = useStage()

  const snapshot = useRef<Map<SnapshotId<K>, D>>(new Map())
  const isConfined = useRef<Map<SnapshotId<K>, boolean>>(new Map())

  const getSnapshotRawData = (key?: K, d?: SnapshotUpdater<D> | D) => {
    const snapshotId = key ?? DEFAULT_SNAPSHOT_ID

    const defaultSnapshot = snapshot.current.get(DEFAULT_SNAPSHOT_ID) ?? {} as D
    const prevSnapshot = snapshot.current.get(snapshotId) ?? {} as D
    const currentData = getShapeData(node.current) as D

    return (isFunction(d) ? d(currentData, prevSnapshot, defaultSnapshot) : d) ?? currentData
  }

  const setSnapshot = (key?: K, data?: SnapshotUpdater<D> | D) => {
    const snapshotId = key ?? DEFAULT_SNAPSHOT_ID
    const resolvedData = getSnapshotRawData(key, data)

    if (!resolvedData) {
      return
    }
    snapshot.current.set(snapshotId, resolvedData)
  }

  const getLatestSnapshot = (key?: K, scale = 1) => {
    const snapshotId = key ?? DEFAULT_SNAPSHOT_ID
    const snap = snapshot.current.get(snapshotId) ?? {} as D

    if (scale === 1 || !isPlainObject(snap)) {
      return snap
    }
    return mapValues(snap, (v, k) => (isNumber(v) && !NOT_SCALABLE_PROPERTIES.includes(k) ? v / scale : v)) as D
  }

  const setConfinedSnapshot = (key?: K, data?: SnapshotUpdater<D> | D) => {
    const resolvedData = getSnapshotRawData(key, data)
    const { W, H } = getAttributes()

    if (!resolvedData) {
      return
    }
    const k = key ?? DEFAULT_SNAPSHOT_ID
    const boundRect = getBoundingRect(resolvedData)
    const minX = MIN_POSITION.x
    const maxX = (W - minX) - boundRect.width
    const minY = MIN_POSITION.y
    const maxY = (H - minY) - boundRect.height

    if (isBounded(boundRect.x, minX, maxX) && isBounded(boundRect.y, minY, maxY)) {
      snapshot.current.set(k, resolvedData)
      isConfined.current.set(k, true)
    } else {
      isConfined.current.set(k, false)
    }
  }

  useEffect(() => {
    const initialData = getShapeData(node.current)

    if (initialSnapshot && initialData) {
      snapshot.current = new Map(Object.entries(initialSnapshot(initialData)))
    }
  }, [])

  return {
    set: setSnapshot,
    setConfined: setConfinedSnapshot,
    get: getLatestSnapshot,
    isConfined: (key?: K) => isConfined.current.get(key ?? DEFAULT_SNAPSHOT_ID) ?? true,
  }
}
