import { isEmpty } from 'lodash'

import { clampMinMax } from '@ahha/utils/number'
import { getRectangleCenter } from '@ahha/stableComponents/Canvas/Rectangle/utils'
import { ViewPortType, MinimapSizeType, RectSizeType } from '@/pages/Labeling/types'
import { ZoomBehavior } from '@/pages/Labeling/const'

export const degreeToRadian = (degree: number) => (degree * Math.PI) / 180

/** (cx, cy)를 중심으로 (x, y)를 회전시킨 rotatedX와 rotatedY를 구하는 함수
 * @param {number} x
 * @param {number} y
 * @param {number} cx
 * @param { number } cy
 * @param { number } degree
 * @returns {{ x, y }}
 */
export const rotationTransformFromCenter = (x: number, y: number, cx: number, cy: number, degree = 0) => {
  const radian = degreeToRadian(degree)
  const relativeX = x - cx
  const relativeY = y - cy

  const rotatedX = (Math.cos(radian) * relativeX - Math.sin(radian) * relativeY) + cx
  const rotatedY = (Math.sin(radian) * relativeX + Math.cos(radian) * relativeY) + cy

  return { x: rotatedX, y: rotatedY }
}

/** DB에 저장될 회전하지 않은 사각형의 top left, bottom right 값을 구하는 함수
 * @param x
 * @param y
 * @param width
 * @param height
 * @param degree
 * @returns {{ xtl, ytl, xbr, ybr }}
 */
export const calcOriginalPointsForDB = (x: number, y: number, width: number, height: number, degree = 0) => {
  const { cx, cy } = getRectangleCenter({ x, y, width, height, rotation: degree })
  const { x: xtl, y: ytl } = rotationTransformFromCenter(x, y, cx, cy, -degree)

  return {
    xtl,
    ytl,
    xbr: (xtl + width),
    ybr: (ytl + height),
  }
}

/** 회전되지 않은 tl(xtl, ytl), br(xbr, ytr)을 중점(cx, cy)기준으로 회전하는 함수, 회전하지 않은 점의 좌표와 각도가 필요하다.
 * tl: top left, br: bottom right
 * @param xtl
 * @param ytl
 * @param xbr
 * @param ybr
 * @param degree
 * @returns {{ x, y, width, height, rotation }}
 */
export const calcRotatedPointsForCanvas = (xtl: number, ytl: number, xbr: number, ybr: number, degree: number) => {
  const cx = xtl + (xbr - xtl) / 2
  const cy = ytl + (ybr - ytl) / 2
  const { x, y } = rotationTransformFromCenter(xtl, ytl, cx, cy, degree)
  return {
    x,
    y,
    width: (xbr - xtl),
    height: (ybr - ytl),
    rotation: degree,
  }
}

/** @deprecated */
export const calculateOverlapArea = (image: RectSizeType, viewport: RectSizeType) => {
  // 이미지 좌표 및 크기
  const { x: imgX, y: imgY, w: imgW, h: imgH } = image
  // 뷰포트 좌표 및 크기
  const { x: vpX, y: vpY, w: vpW, h: vpH } = viewport
  // 이미지와 뷰포트의 상대적인 크기 및 위치 계산
  const relativeX = vpX - imgX
  const relativeY = vpY - imgY
  const relativeWidth = Math.min(imgX + imgW, vpX + vpW) - Math.max(imgX, vpX)
  const relativeHeight = Math.min(imgY + imgH, vpY + vpH) - Math.max(imgY, vpY)
  // 겹치는 영역의 좌표와 크기 계산
  const overlapX = Math.max(0, relativeX)
  const overlapY = Math.max(0, relativeY)
  const overlapWidth = Math.min(vpW, relativeWidth)
  const overlapHeight = Math.min(vpH, relativeHeight)
  return {
    x: overlapX,
    y: overlapY,
    w: overlapWidth,
    h: overlapHeight,
  }
}

/** @deprecated */
export const handleScaleMinimapRect = (minimap: MinimapSizeType, viewPort: ViewPortType, overlapRect: RectSizeType) => {
  if (isEmpty(minimap) || isEmpty(viewPort) || isEmpty(overlapRect)) {
    return { x: 0, y: 0, w: 0, h: 0 }
  }
  const { width = 1, height = 1 } = viewPort
  const widthRatio = minimap.width / width
  const heightRatio = minimap.height / height
  const ratio = Math.min(widthRatio, heightRatio)
  const { x, y, w, h } = overlapRect
  const newX = x * ratio
  const newY = y * ratio
  const newW = w * ratio
  const newH = h * ratio
  return { x: newX, y: newY, w: newW, h: newH }
}

export const getZoomValueFromScale = (behavior: typeof ZoomBehavior.ZOOM_IN | typeof ZoomBehavior.ZOOM_OUT, initialScale: number, zoomValue: number) => {
  const maxScale = initialScale * 2.5
  const minScale = initialScale * 0.5

  /** 상수값으로 설정하게 되면 이미지에 따라 확대/축소 단계가 달라지게 되므로 자연스러운 확대/축소를 위해 초기 스케일에 비례한 값으로 설정 */
  const stepPerScroll = initialScale * 0.2
  const zoomStep = (behavior === ZoomBehavior.ZOOM_IN ? 1 : -1) * stepPerScroll
  return clampMinMax(zoomValue + zoomStep, minScale, maxScale)
}

/** @deprecated */
export const rotateAPointBasedOnOrigin = (x: number, y: number, degree: number) => {
  const radian = degreeToRadian(degree)
  const rotatedX = Math.cos(radian) * x - Math.sin(radian) * y
  const rotatedY = Math.sin(radian) * x + Math.cos(radian) * y
  return { x: rotatedX, y: rotatedY }
}

/** @deprecated */
export const getCenter = (x: number, y: number, width: number, height: number, degree: number) => {
  // 회전한 사각형의 중심을 구하는 식
  // 변경된 topLeft(x, y)을 기준으로 회전한 사각형의 중심을 구하는 함수
  // 먼저 원점(0, 0)을 topLeft로 가지는 너비가 width 높이가 height 사각형의 중점을 구함
  // 위의 사각형의 중점의 좌표는 (width/2, height/2)이 됨
  // 이후에 topLeft을 중심으로 (width/2, height/2)를 degree 만큼 회전함
  const { x: rotatedX, y: rotatedY } = rotateAPointBasedOnOrigin(width / 2, height / 2, degree)
  // 점(x, y)좌표만큼 이동시켜 기울어진 사각형의 중심점(cx, cy)을 구함
  return { x: rotatedX + x, y: rotatedY + y }
}
