import { RefObject, useCallback, useEffect } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import useStageCursor from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useStageCursor'
import useRevertCursorOnQuit from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useRevertCursorOnQuit'
import { registerEvent, unregisterEvent } from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/utils'
import { isRotateAnchor } from '../../konva'
import { useFlagMode } from './useFlagMode'

interface RotationCursorParams {
  enabled: boolean
  initialRotation: number
  transformer: RefObject<Konva.Transformer>
}

const ROTATION_ANCHOR = 'rotater'

const CURSOR_CENTER_X = 10
const CURSOR_CENTER_Y = 10
const CURSOR_CENTER_POINT = `${CURSOR_CENTER_X} ${CURSOR_CENTER_Y}`

export const useRotationCursor = ({
  enabled,
  initialRotation,
  transformer,
}: RotationCursorParams) => {
  const [setCursor, revertCursor, stage] = useStageCursor()
  const [isRotating, startRotate, endRotate] = useFlagMode()

  useRevertCursorOnQuit(enabled)

  const setTrCursor = (cursor: string) => transformer.current?.rotateAnchorCursor(cursor)

  const isRotateAnchorActive = () => {
    const activeAnchor = transformer.current?.getActiveAnchor() ?? ''

    return isRotateAnchor(activeAnchor)
  }

  const setRotationStart = useCallback(() => {
    if (isRotateAnchorActive()) {
      startRotate()
    }
  }, [])

  const setInitialCursor = useCallback(() => {
    const rotation = Math.floor(initialRotation)

    setTrCursor(`url("${createRotationCursor(rotation)}") ${CURSOR_CENTER_POINT}, auto`)
  }, [initialRotation])

  const setRotationCursor = useCallback(() => {
    const activeAnchor = transformer.current?.getActiveAnchor()
    const rotation = Math.floor(transformer.current?.rotation() ?? 0)

    if (activeAnchor !== ROTATION_ANCHOR) {
      return
    }
    setCursor(`url("${createRotationCursor(rotation)}") ${CURSOR_CENTER_POINT}, auto`)
  }, [])

  const revertRotationCursor = useCallback((e: KonvaEventObject<MouseEvent>) => {
    /** Stage 이벤트 핸들러이므로 rotate transform이 아닐 경우 아무 작업도 하지 않음 */
    if (!isRotating()) {
      return
    }
    const targetName = e.target.name()

    if (!isRotateAnchor(targetName)) {
      revertCursor()
    }
    endRotate()
  }, [])

  useEffect(() => {
    const rotationNode = transformer.current?.findOne('.rotater')

    if (rotationNode) {
      registerEvent(rotationNode, 'mouseover', setInitialCursor)
    }

    return () => {
      unregisterEvent(rotationNode, 'mouseover', setInitialCursor)
    }
  }, [setInitialCursor])

  useEffect(() => {
    const tr = transformer.current

    if (enabled && tr) {
      registerEvent(tr, 'transformstart', setRotationStart)
      registerEvent(tr, 'transform', setRotationCursor)
      registerEvent(stage, 'mouseup', revertRotationCursor)
    }

    return () => {
      unregisterEvent(tr, 'transformstart', setRotationStart)
      unregisterEvent(tr, 'transform', setRotationCursor)
      unregisterEvent(stage, 'mouseup', revertRotationCursor)
    }
  }, [enabled])
}

export const createRotationCursor = (rotation: number) => {
  const svg = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="rotate(${rotation} ${CURSOR_CENTER_POINT})">
      <path d="M2.02344 7.16789C7.93567 5.30032 15.2123 7.79621 15.2123 16.9833M2.02344 7.16789L4.20363 4.02344M2.02344 7.16789L5.19038 8.95717M15.2123 16.9833L12.4923 14.6803M15.2123 16.9833L17.3274 14.0881" stroke="black" strokeWidth="2.8" strokeLinejoin="round" />
      <path d="M2.02344 7.16789C7.93567 5.30032 15.2123 7.79621 15.2123 16.9833M2.02344 7.16789L4.20363 4.02344M2.02344 7.16789L5.19038 8.95717M15.2123 16.9833L12.4923 14.6803M15.2123 16.9833L17.3274 14.0881" stroke="white" strokeWidth="2.8" strokeLinejoin="round" />
      <path d="M2 7.14445C7.91223 5.27689 15.1889 7.77277 15.1889 16.9599M2 7.14445L4.1802 4M2 7.14445L5.16694 8.93373M15.1889 16.9599L12.4689 14.6569M15.1889 16.9599L17.3039 14.0646" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M2 7.14445C7.91223 5.27689 15.1889 7.77277 15.1889 16.9599M2 7.14445L4.1802 4M2 7.14445L5.16694 8.93373M15.1889 16.9599L12.4689 14.6569M15.1889 16.9599L17.3039 14.0646" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinejoin="round" />
    </g>
  </svg>`

  return `data:image/svg+xml,${encodeURIComponent(svg)}`
}
