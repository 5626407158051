import { CornerAnchors } from '@ahha/stableComponents/Canvas/types/shape'
import { RectangleConfig } from '@ahha/stableComponents/Canvas/types/theme'

export const DEFAULT_THEME_CONFIG = {
  rect: {
    stroke: '#7785FF',
    strokeWidth: 3,
    fill: 'transparent',
    dash: undefined,
    opacity: 1,
    cornerRadius: 0,
  },
  outline: {
    offsetX: 0,
    offsetY: 0,
    stroke: '#7785FF',
    strokeWidth: 1,
    dash: undefined,
    cornerRadius: 0,
  },
  transformer: {
    anchor: {
      size: 5,
      stroke: '#FFF',
      strokeWidth: 2,
      cornerRadius: 0,
      fill: '#FFF',
    },
    border: {
      stroke: '#FFF',
      strokeWidth: 0,
      dash: undefined,
      rotateAnchorOffset: 20,
    },
  },
} satisfies RectangleConfig

export const MIN_POSITION = {
  x: 0.1,
  y: 0.1,
}

export const MIN_RECT_SIZE = 5

export const RECTANGLE_ANCHORS = [
  'top-left',
  'top-center',
  'top-right',
  'middle-right',
  'middle-left',
  'bottom-left',
  'bottom-center',
  'bottom-right',
]

export const SQUARE_ANCHORS = [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
]

export const SQUARE_SNAPSHOT_ID = 'SQUARE'
