import { createSelector } from '@reduxjs/toolkit'
import { filter, map } from 'lodash'

import { CommentProps } from '@ahha/stableComponents/Canvas/Comment/Comment'
import { isArray, isIn } from '@ahha/utils/@types/typeChecks'
import { RootState } from '@/stores'
import { LabelingNodeItem } from '@/api/LabelingNode/types'
import { LABEL_OK_STATUS, LABEL_FILE_STATUS, LABEL_FINISHED } from '@/api/LabelingNode/const'
import { LabelingObjectData, SelectedObjectType } from '@/stores/slices/Labeling/types'
import { isMac } from '@/pages/Labeling/components/ShortcutDrawer/os'
import { OS_TYPE } from '@/pages/Labeling/components/ShortcutDrawer/hotKeys'

const selectSelf = (state: RootState) => state.labeling
export const selectItems = (state: RootState) => state.labeling.files
export const selectOriginalItems = (state: RootState) => state.labeling.originalFiles
export const selectAnnotation = (state: RootState) => state.labeling.annotation

export const selectCurrentIndex = createSelector(
  selectSelf,
  (self) => self.currentIndex
)

export const selectLabelingNodeInfo = createSelector(
  selectSelf,
  (self) => ({
    id: self.labelingNodeId,
    name: self.name,
    numFiles: self.files.length,
    type: self.modelType,
    datasetNodeId: self.datasetInfo.datasetNodeId,
    datasetNodeName: self.datasetInfo.name,
    classGroupId: self.classGroupId,
  })
)

export const selectFilteredFiles = createSelector(
  selectItems,
  (s: RootState) => s.labeling.fileFilter,
  // eslint-disable-next-line arrow-body-style
  (files, filter) => {
    // if (filter === 'ALL') {
    //   return files
    // }
    // const filters = isArray(filter) ? new Set(filter) : new Set([filter])
    //
    // return files.filter((f) => f && filters.has(f.status))
    return files
  }
)

export const selectLabelingFileInfo = createSelector(
  [
    selectFilteredFiles,
    (s: RootState) => s.labeling.currentIndex,
    (state, index?: number) => index,
  ],
  (items, storeIndex, index) => {
    const file = items[index ?? storeIndex]
    return file as LabelingNodeItem
  }
)

export const selectClasses = createSelector(
  (s: RootState) => s.labeling.classes,
  (classes) => classes.map((c) => {
    if (c.color.startsWith('#')) {
      return c
    }
    return { ...c, color: `#${c.color}` }
  }) ?? []
)

export const selectTool = createSelector([selectSelf, selectClasses], (self, classes) => {
  const selectedClass = classes.find((c) => c._id === self.tool.class) // TODO: _id인지 체크 필요
  if (!selectedClass) {
    return self.tool
  }
  return {
    ...self.tool,
    classColor: selectedClass.color,
  }
})

export const selectObjects = createSelector(
  [
    selectFilteredFiles,
    selectAnnotation,
    selectClasses,
    (state, index: number) => index,
  ],
  // TODO: change return type
  (items, annot, classes, index): SelectedObjectType<LabelingObjectData>[] => {
    const itemId = items[index || 0]?._id ?? ''
    const annotation = annot[itemId]
    const isUninitializedItem = !itemId || !annotation
    if (isUninitializedItem) {
      return []
    }
    const objects = annotation.object
    return map(objects, (o) => {
      const { label } = o
      const labelClass = classes.find((c) => c._id === label)
      return {
        ...o,
        className: labelClass?.name || 'UNKNOWN',
        color: labelClass?.color || '#ff0000',
      }
    })
    // const snapshotIndex = annotation.count - annotation.index
    // if (snapshotIndex >= annotation.snapshot.length || snapshotIndex < 0) {
    //   console.warn(`count: ${annotation.count}, index: ${annotation.index}, length: ${annotation.snapshot.length}. Something has gone wrong`)
    //   return []
    // }
    // 파일 단위로 저장할때는 isDeleted가 true인 오브젝트들 필터링 필요
    // const filteredObjects = filter(annotation.snapshot[snapshotIndex].object, (o) => !o.isDeleted)
    // TODO: return 값 확인 필요
    // return map(filteredObjects, (o) => {
    //   const { label } = o
    //   const labelClass = classes.find((c) => c._id === label)
    //   return {
    //     ...o,
    //     className: labelClass?.name || 'UNKNOWN',
    //     color: labelClass?.color || '#ff0000',
    //   }
    // })
  }
)

export const selectComments = createSelector(
  [
    selectFilteredFiles,
    (s: RootState) => s.labeling.comments,
    (s: RootState) => s.labeling.userIsReviewer,
    (s: RootState) => s.user.user,
    (state, index: number) => index,
  ],
  (items, comments, isReviewer, user, index) => {
    const activeUserData = {
      ...user,
      isReviewer,
      id: user._id,
    }
    const currentCommentList = comments[items[index || 0]?._id || ''] || []

    return currentCommentList.reduce((a, c) => {
      const { x, y, width, height, anchor } = c
      const inPoint = x !== undefined && y !== undefined
      const isArea = x !== undefined && y !== undefined && width !== undefined && height !== undefined

      if (anchor) {
        a.push({ ...c, anchor, user: activeUserData })
      } else if (isArea) {
        a.push({ ...c, x, y, width, height, user: activeUserData })
      } else if (inPoint) {
        a.push({ ...c, x, y, user: activeUserData })
      }
      return a
    }, [] as CommentProps[])
  }
)

export const selectIsReviewNode = createSelector(
  selectSelf,
  (self) => self.initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS
  // (self) => self.initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS || self.initialNodeStatus === LABEL_FILE_STATUS.COMPLETED || self.initialNodeStatus === LABEL_FILE_STATUS.REJECTED
)

export const selectIncompleteCount = createSelector(
  [selectOriginalItems, selectIsReviewNode],
  (items, isReviewNode) => {
    if (isReviewNode) {
      return filter(items, (f) => !isIn([LABEL_FILE_STATUS.REJECTED, LABEL_FILE_STATUS.COMPLETED], f?.status)).length
    }
    return filter(items, (f) => !isIn([LABEL_FILE_STATUS.FINISHED, LABEL_FILE_STATUS.COMPLETED], f?.status)).length
  }
)

export const selectOsCompatibleShortcuts = createSelector(
  (s: RootState) => s.labeling.userShortcuts,
  (shortcuts) => (isMac ? filter(shortcuts, { type: OS_TYPE.MAC }) : filter(shortcuts, { type: OS_TYPE.WIN }))
)

export const selectFileLabelOk = createSelector(
  selectLabelingFileInfo,
  (file) => {
    const { status, numOfLabelObjects } = file ?? {}

    if (!status || numOfLabelObjects === undefined) {
      return ''
    }
    const isFinished = isIn(LABEL_FINISHED, status)
    const hasObjects = numOfLabelObjects > 0

    if (isFinished && !hasObjects) {
      return LABEL_OK_STATUS.ok
    }
    if (isFinished && hasObjects) {
      return LABEL_OK_STATUS.ng
    }
    return LABEL_OK_STATUS.waiting
  }
)

export const selectHasNoImage = createSelector(
  selectFilteredFiles,
  (s: RootState) => s.labeling.labelingNodeId,
  (s: RootState) => s.labeling.fileFilter,
  (files, nodeId, filter) => {
    if (!nodeId) {
      return false
    }
    // if (filter !== 'ALL' && files.length < 1) {
    //   return true
    // }
    if (files.length === 0) {
      return true
    }
    return false
  }
)
