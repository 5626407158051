import { createAsyncThunk } from '@reduxjs/toolkit'
import { find } from 'lodash'

import { API } from '@/api'
import { LabelingObjectData, ThunkConfig } from '@/stores/slices/Labeling/types'
import { serializeObject } from '@/stores/slices/Labeling/utils'
import { socket } from '@/socket'

type CommonPayload = { itemId: string, objectId: string }
type UpdateBoxPayload = CommonPayload & {
  x: number
  y: number
  width: number
  height: number
  rotation: number
}
type UpdateEllipsePayload = CommonPayload & {
  cx: number
  cy: number
  rx: number
  ry: number
  rotation: number
}
type UpdatePolygonPayload = CommonPayload & { points: { x: number, y: number }[] }
type UpdateObjectClassPayload = CommonPayload & { classId: string }
type UpdatedObject = { itemId: string, object?: LabelingObjectData }

export const updateBox = createAsyncThunk<UpdatedObject, UpdateBoxPayload, ThunkConfig>(
  'labeling/update-box-object',
  async (payload, thunkAPI) => {
    const { itemId, objectId, ...boxProperties } = payload
    const { user } = thunkAPI.getState().user
    const { labelingNodeId, annotation } = thunkAPI.getState().labeling
    const { object } = annotation[itemId]
    const prevObject = find(object, (o) => o.id === objectId)
    if (prevObject) {
      const updatedObject = { ...prevObject, ...boxProperties, modifiedAt: new Date() }
      await API().Labeling.updateObject(serializeObject(updatedObject, itemId, prevObject.name))
      socket.emitEditObject({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId,
        updatedObject,
      } as any)
      return { itemId, object: updatedObject }
    }
    return { itemId }
  }
)

export const updateEllipse = createAsyncThunk<UpdatedObject, UpdateEllipsePayload, ThunkConfig>(
  'labeling/update-ellipse-object',
  async (payload, thunkAPI) => {
    const { itemId, objectId, ...ellipseProperties } = payload
    const { user } = thunkAPI.getState().user
    const { labelingNodeId, annotation } = thunkAPI.getState().labeling
    const { object } = annotation[itemId]
    const prevObject = find(object, (o) => o.id === objectId)
    if (prevObject) {
      const updatedObject = { ...prevObject, ...ellipseProperties, modifiedAt: new Date() }
      await API().Labeling.updateObject(serializeObject(updatedObject, itemId, prevObject.name))
      socket.emitEditObject({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId,
        updatedObject,
      } as any)
      return { itemId, object: updatedObject }
    }
    return { itemId }
  }
)

export const updatePolygon = createAsyncThunk<UpdatedObject, UpdatePolygonPayload, ThunkConfig>(
  'labeling/update-polygon-object',
  async (payload, thunkAPI) => {
    const { itemId, objectId, points } = payload
    const { user } = thunkAPI.getState().user
    const { labelingNodeId, annotation } = thunkAPI.getState().labeling
    const { object } = annotation[itemId]
    const prevObject = find(object, (o) => o.id === objectId)
    if (prevObject) {
      const updatedObject = { ...prevObject, points, modifiedAt: new Date() }
      await API().Labeling.updateObject(serializeObject(updatedObject, itemId, prevObject.name))
      socket.emitEditObject({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId,
        updatedObject,
      } as any)
      return { itemId, object: updatedObject }
    }
    return { itemId }
  }
)

export const updateObjectClass = createAsyncThunk<UpdatedObject, UpdateObjectClassPayload, ThunkConfig>(
  'labeling/update-object-class',
  async (payload, thunkAPI) => {
    const { itemId, objectId, classId } = payload
    const { user } = thunkAPI.getState().user
    const { labelingNodeId, annotation } = thunkAPI.getState().labeling
    const { object } = annotation[itemId]
    const prevObject = find(object, (o) => o.id === objectId)
    if (prevObject) {
      const updatedObject = { ...prevObject, label: classId, modifiedAt: new Date() }
      await API().Labeling.updateObject(serializeObject(updatedObject, itemId, prevObject.name))
      socket.emitChangeObjectClass({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId,
        updatedObject,
      } as any)
      return { itemId, object: updatedObject }
    }
    return { itemId }
  }
)
