import { RefObject, useCallback, useEffect } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import useStageCursor from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useStageCursor'
import useRevertCursorOnQuit from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/useRevertCursorOnQuit'
import { registerEvent, unregisterEvent } from '@ahha/stableComponents/Canvas/utils/hooks/useCursors/utils'

interface TransitionCursorParams<T> {
  enabled: boolean
  ref: RefObject<T>
  transformer?: RefObject<Konva.Transformer>
}

export const useTransitionCursor = <T extends Konva.Shape>({
  enabled,
  ref,
  transformer,
}: TransitionCursorParams<T>) => {
  const [setCursor, revertCursor] = useStageCursor()
  useRevertCursorOnQuit(enabled)

  const setTransitionCursor = useCallback(() => {
    const isTransforming = transformer?.current?.isTransforming() ?? false

    if (isTransforming) {
      return
    }
    setCursor('move')
  }, [])

  const revertWhenMouseOut = useCallback((e: KonvaEventObject<MouseEvent>) => {
    /* TODO:ksh: 나간 위치가 anchor이면 revert 금지 */
    revertCursor()
  }, [])

  useEffect(() => () => {
    revertCursor()
  }, [])

  useEffect(() => {
    const shape = ref.current

    if (enabled && shape) {
      setTransitionCursor()

      registerEvent(shape, 'mousemove', setTransitionCursor)
      registerEvent(shape, 'mouseout', revertWhenMouseOut)
    } else {
      /* FIXME:ksh: 내부에서 측면 anchor로 커서를 가져가면 커서가 resize가 아니라 default로 바뀌는 문제. */
      // revertCursor()
    }

    return () => {
      unregisterEvent(shape, 'mousemove', setTransitionCursor)
      unregisterEvent(shape, 'mouseout', revertWhenMouseOut)
    }
  }, [enabled])
}
