import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn'

import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

/* const duration = require('dayjs/plugin/duration') */

dayjs.extend(duration)
dayjs.extend(relativeTime)

export const formatDate = (
  date: Date | string,
  form: string,
  ignoreCurrentLang?: boolean
) => {
  const language = dayjs.locale()

  if (ignoreCurrentLang) {
    return dayjs(date).format(form)
  }

  switch (language) {
    case 'en': {
      const [
        _,
        year,
        sp1 = '',
        month = '',
        sp2 = '',
        day = '',
        rest = '',
      ] = form.split(/(Y{4})([\s/.-]*)(M{1,2})([\s/.-]*)(D{1,2})?/) // FIXME: safari 에러로 임시로 수정한 코드

      return dayjs(date).format(`${month}${sp1}${day}${sp2}${year}${rest}`)
    }
    default:
      return dayjs(date).format(form)
  }
}

type TimeFormat = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'

export const formatTimeDuration = (date: number, type: TimeFormat, form: string) => {
  const durationTime = dayjs.duration(date, type)

  if (form.startsWith('HH')) {
    const hours = +Math.floor(durationTime.asHours())
      .toString()
      .padStart(2, '0')
    const minutes = +Math.floor(durationTime.asMinutes() - hours * 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(durationTime.asSeconds() - hours * 60 * 60 - minutes * 60)
      .toString()
      .padStart(2, '0')

    return `${hours}:${minutes}:${seconds}`
  }
  return durationTime.format(form)
}

export const getDateWithStartTime = (date: Date) => dayjs(date).startOf('day').toDate()

export const getDateWithEndTime = (date: Date) => dayjs(date).endOf('day').toDate()

export const formatDateTime = (date: Date | string, dateFormat: string, timeFormat: string) => {
  const shouldShowTime = timeFormat !== 'none'
  const instance = dayjs(date)

  if (instance.isValid()) {
    return instance.format(`${dateFormat} ${shouldShowTime ? timeFormat : ''}`)
  }
  throw new Error(`value: ${date} is not a valid date.`)
}

export const humanizeDate = (date: string | Date) => {
  if (!dayjs(date).isValid()) {
    throw new Error(`value: ${date} is not a valid date.`)
  }
  return dayjs(date).fromNow()
}
