import { retry } from '@ahha/utils/network/retry'
import { API } from '@/api'
import { AppListenerMiddleware } from '@/stores'
import { setFileFilter, toggleFileFinished } from '@/stores/slices/Labeling/labelingSlice'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'
import { selectFilteredFiles } from '@/stores/slices/Labeling/labelingSelector'
import { socket } from '@/socket'
import { queryClient } from '@/api/react-query/queryClient'
import { labelingNodeKeys } from '@/api/react-query/queryKeys/labelingNode'
import { loadObjects } from '@/stores/slices/Labeling/thunks/objects'

export const fileStatusListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: toggleFileFinished,
  effect: async (action, api) => {
    const { user } = api.getState().user
    const { parentNodeId, currentIndex, initialNodeStatus, labelingNodeId } = api.getState().labeling
    const files = selectFilteredFiles(api.getOriginalState())
    const fileId = files[currentIndex]?._id

    const isReview = initialNodeStatus === LABEL_FILE_STATUS.REQUEST_REVIEW || initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS

    if (fileId) {
      if (isReview) {
        const res = await retry(() => API().LabelingReview.toggleReviewForFileFinished(fileId))
        socket.emitFinishLabelingItem({
          nodeId: labelingNodeId,
          userId: user._id,
          userName: user.name,
          itemId: fileId,
          updatedStatus: res.status,
        } as any)
      } else {
        const res = await retry(() => API().Labeling.toggleFileFinished(fileId))
        socket.emitFinishLabelingItem({
          nodeId: labelingNodeId,
          userId: user._id,
          userName: user.name,
          itemId: fileId,
          updatedStatus: res.status,
        } as any)
      }
      await queryClient.invalidateQueries({
        queryKey: labelingNodeKeys.detail(parentNodeId),
      })
    }
  },
})

export const filterListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: setFileFilter,
  effect: async (action, api) => {
    api.dispatch(loadObjects({ index: 0 }))
  },
})
