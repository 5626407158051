/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
import BaseApi from '@/api/Base/BaseApi'
import UserApi from '@/api/User/UserApi'
import WorkspaceApi from '@/api/Workspace/WorkspaceApi'
import NotificationApi from '@/api/Notification/NotificationApi'
import DatabaseApi from '@/api/Database/DatabaseApi'
import DashboardApi from '@/api/Dashboard/DashboardApi'
import ProjectApi from '@/api/Project/ProjectApi'
import StorageApi from '@/api/Storage/StorageApi'
import DatasetNodeApi from '@/api/DatasetNode/DatasetNodeApi'
import LabelingNodeApi from '@/api/LabelingNode/LabelingNodeApi'
import LabelingNodeReviewApi from '@/api/LabelingNode/LabelingNodeReviewApi'
import ClassGroupApi from '@/api/ClassGroup/ClassGroupApi'
import ModelNodeApi from '@/api/ModelNode/ModelNodeApi'
import ValidationNodeApi from '@/api/ValidationNode/ValidationNodeApi'
import InferenceNodeApi from '@/api/InferenceNode/InferenceNodeApi'
import HyperparametersApi from '@/api/Hyperparameters/HyperparametersApi'
import InvitationApi from '@/api/Invitation/InvitationApi'
import ImageMatchingNodeApi from '@/api/ImageMatchingNode/ImageMatchingNodeApi'
import PreprocessingNodeApi from '@/api/PreprocessingNode/PreprocessingNodeApi'
import PreprocessingResultNodeApi from '@/api/PreprocessingResultNode/PreprocessingResultNodeApi'

class Api extends BaseApi {
  static proto: Api

  #instance: Api | undefined

  constructor(signal?: AbortSignal, isInstance = false) {
    super(signal)
    this.#instance = this

    if (isInstance) {
      return this.#instance
    }
    if (Api.proto) {
      return Api.proto
    }
    Api.proto = this
  }

  withSignal(signal: AbortSignal) {
    return new Api(signal, true)
  }

  User = new UserApi(this.signal)

  Workspace = new WorkspaceApi(this.signal)

  Notifications = new NotificationApi(this.signal)

  Project = new ProjectApi(this.signal)

  Database = new DatabaseApi(this.signal)

  Dashboard = new DashboardApi(this.signal)

  Storage = new StorageApi(this.signal)

  DatasetNode = new DatasetNodeApi(this.signal)

  Preprocessing = new PreprocessingNodeApi(this.signal)

  PreprocessingResult = new PreprocessingResultNodeApi(this.signal)

  Labeling = new LabelingNodeApi(this.signal)

  LabelingReview = new LabelingNodeReviewApi(this.signal)

  ClassGroup = new ClassGroupApi(this.signal)

  ImageMatching = new ImageMatchingNodeApi(this.signal)

  ModelNode = new ModelNodeApi(this.signal)

  ValidationNode = new ValidationNodeApi(this.signal)

  InferenceNode = new InferenceNodeApi(this.signal)

  Hyperparameters = new HyperparametersApi(this.signal)

  Invitation = new InvitationApi(this.signal)
}

export const API = () => new Api()
