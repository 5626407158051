import BaseApi from '@/api/Base/BaseApi'
import {
  CheckValidRequirementsPayload,
  CreateModelNodePayload,
  GetTrainResultResponse,
  GetTrainStatusResponse,
  ModelNodeInfo,
  UpdateModelNodePayload
} from '@/api/ModelNode/types'
import { CommonListPayload } from '@/api/DatasetNode/types'
import { GetLabelingNodeItemsResponse } from '@/api/LabelingNode/types'

const ROOT = '/model'

const defaultPaginationOptions = {
  itemsPerPage: 40,
  page: 0,
  sortDir: 'asc',
  sortKey: 'datasetItemId',
}

export default class ModelNodeApi extends BaseApi {
  createModelNode(payload: CreateModelNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ModelNodeInfo>(`/project/${projectId}/model/create`, rest)
  }

  getModelNodeDetail(modelNodeId: string) {
    return this.agent.get<ModelNodeInfo>(`${ROOT}/${modelNodeId}`)
  }

  updateModelNode(payload: UpdateModelNodePayload) {
    const { modelNodeId, ...rest } = payload
    return this.agent.put<ModelNodeInfo>(`${ROOT}/${modelNodeId}`, rest)
  }

  deleteModelNode(modelNodeId: string) {
    return this.agent.delete(`${ROOT}/${modelNodeId}/delete`)
  }

  startTrain(modelNodeId: string) {
    return this.agent.post<GetTrainResultResponse>(`${ROOT}/${modelNodeId}/train`)
  }

  stopTrain(modelNodeId: string) {
    return this.agent.put<GetTrainStatusResponse>(`${ROOT}/${modelNodeId}/train/stop`)
  }

  getTrainStatus(modelNodeId: string) {
    return this.agent.get<GetTrainStatusResponse>(`${ROOT}/${modelNodeId}/train/status`)
  }

  getTrainResult(modelNodeId: string) {
    return this.agent.get(`${ROOT}/${modelNodeId}/train`)
  }

  getTrainLoss(modelNodeId: string) {
    return this.agent.get(`${ROOT}/${modelNodeId}/train/loss-graph`)
  }

  checkValidRequirements(payload: CheckValidRequirementsPayload) {
    const { modelNodeId, ...rest } = payload
    return this.agent.post<ModelNodeInfo>(`${ROOT}/${modelNodeId}/training-check`, rest)
  }

  resetModelNode(modelNodeId: string) {
    return this.agent.put(`${ROOT}/${modelNodeId}/reset`)
  }

  getTrainItems(modelNodeId: string, options?: CommonListPayload) {
    const { itemsPerPage, page, sortDir, sortKey } = options ?? defaultPaginationOptions
    return this.agent.get<GetLabelingNodeItemsResponse>(`${ROOT}/${modelNodeId}/items`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }
}
