import { isMac } from '@/pages/Labeling/components/ShortcutDrawer/os'
import { UserShortcut } from '@/api/LabelingNode/types'

export const COMMON_SHORTCUTS = {
  Mac: {
    copy: 'Cmd+KeyC',
    paste: 'Cmd+KeyV',
    cut: 'Cmd+KeyX',
    delete1: 'Delete',
    backspaceDelete: 'Backspace',
    delete2: 'Escape',
    zoomIn: 'Cmd+Equal',
    zoomOut: 'Cmd+Minus',
    undo: 'Cmd+KeyZ',
    redo: 'Cmd+Shift+KeyZ',
    save: 'Cmd+KeyS',
  },
  Windows: {
    copy: 'Ctrl+KeyC',
    paste: 'Ctrl+KeyV',
    cut: 'Ctrl+KeyX',
    delete1: 'Delete',
    backspaceDelete: 'Backspace',
    delete2: 'Escape',
    zoomIn: 'Ctrl+Equal',
    zoomOut: 'Ctrl+Minus',
    undo: 'Ctrl+KeyZ',
    redo: 'Ctrl+Shift+KeyZ',
    save: 'Ctrl+KeyS',
  },
} as const

export const FILE_SHORTCUTS = {
  Mac: {
    toggleImageDrawer: '',
    prevImage: '',
    nextImage: '',
    toggleTracking: '',
  },
  Windows: {
    toggleImageDrawer: '',
    prevImage: '',
    nextImage: '',
    toggleTracking: '',
  },
} as const

export const LABEL_SHORTCUTS = {
  Mac: {
    selectClass: '',
    pointer: 'KeyA',
    rectangle: 'KeyR',
    ellipse: 'KeyE',
    polygon: 'KeyP',
    brush: 'KeyB',
    magicWand: '',
    autoPixel: '',
    opacity: '',
  },
  Windows: {
    selectClass: '',
    pointer: 'KeyA',
    rectangle: 'KeyR',
    ellipse: 'KeyE',
    polygon: 'KeyP',
    brush: 'KeyB',
    magicWand: '',
    autoPixel: '',
    opacity: '',
  },
} as const

export const OS_TYPE = {
  MAC: 'MAC',
  WIN: 'WIN',
}

export const ACTION_TYPE = {
  common: {
    TOGGLE_SHORTCUT_DRAWER: 'toggleShortcutDrawer',
    ZOOM_IN: 'zoomIn',
    ZOOM_OUT: 'zoomOut',
    UNDO: 'undo',
    REDO: 'redo',
    TOGGLE_IMAGE_DRAWER: 'toggleImageDrawer',
    PREV_IMAGE: 'prevImage',
    NEXT_IMAGE: 'nextImage',
  },
  labeling: {
    SELECT_CLASS: 'selectClass',
    POINTER: 'pointer',
    COMMENT: 'comment',
    RECTANGLE: 'rectangle',
    ELLIPSE: 'ellipse',
    POLYGON: 'polygon',
    BRUSH: 'brush',
    MAGIC_WAND: 'magicWand',
    AUTO_PIXEL: 'autoPixel',
    OPACITY: 'opacity',
    TOGGLE_TRACKING: 'toggleStamp',
  },
} as const

// TODO: fix default keys
export const DEFAULT_SHORTCUTS: UserShortcut[] = [
  { action: ACTION_TYPE.common.TOGGLE_SHORTCUT_DRAWER, shortcutKey: 'KeyD', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.ZOOM_IN, shortcutKey: 'Cmd+Equal', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.ZOOM_OUT, shortcutKey: 'Cmd+Minus', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.UNDO, shortcutKey: 'Cmd+KeyZ', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.REDO, shortcutKey: 'Cmd+Shift+KeyZ', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.TOGGLE_IMAGE_DRAWER, shortcutKey: 'KeyI', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.PREV_IMAGE, shortcutKey: 'KeyQ', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.common.NEXT_IMAGE, shortcutKey: 'KeyW', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.SELECT_CLASS, shortcutKey: 'KeyC', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.POINTER, shortcutKey: 'KeyV', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.COMMENT, shortcutKey: 'Shift+KeyR', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.RECTANGLE, shortcutKey: 'KeyR', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.ELLIPSE, shortcutKey: 'KeyE', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.POLYGON, shortcutKey: 'KeyP', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.BRUSH, shortcutKey: 'KeyB', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.MAGIC_WAND, shortcutKey: 'KeyM', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.AUTO_PIXEL, shortcutKey: 'KeyA', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.OPACITY, shortcutKey: 'KeyO', type: OS_TYPE.MAC },
  { action: ACTION_TYPE.labeling.TOGGLE_TRACKING, shortcutKey: 'KeyT', type: OS_TYPE.MAC },
  // WINDOWS
  { action: ACTION_TYPE.common.TOGGLE_SHORTCUT_DRAWER, shortcutKey: 'KeyD', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.ZOOM_IN, shortcutKey: 'Ctrl+Equal', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.ZOOM_OUT, shortcutKey: 'Ctrl+Minus', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.UNDO, shortcutKey: 'Ctrl+KeyZ', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.REDO, shortcutKey: 'Ctrl+Shift+KeyZ', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.TOGGLE_IMAGE_DRAWER, shortcutKey: 'KeyI', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.PREV_IMAGE, shortcutKey: 'KeyQ', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.common.NEXT_IMAGE, shortcutKey: 'KeyW', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.SELECT_CLASS, shortcutKey: 'KeyC', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.POINTER, shortcutKey: 'KeyV', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.COMMENT, shortcutKey: 'Shift+KeyR', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.RECTANGLE, shortcutKey: 'KeyR', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.ELLIPSE, shortcutKey: 'KeyE', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.POLYGON, shortcutKey: 'KeyP', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.BRUSH, shortcutKey: 'KeyB', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.MAGIC_WAND, shortcutKey: 'KeyM', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.AUTO_PIXEL, shortcutKey: 'KeyA', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.OPACITY, shortcutKey: 'KeyO', type: OS_TYPE.WIN },
  { action: ACTION_TYPE.labeling.TOGGLE_TRACKING, shortcutKey: 'KeyT', type: OS_TYPE.WIN },
]

const commonHotKeys = isMac ? COMMON_SHORTCUTS.Mac : COMMON_SHORTCUTS.Windows
const fileHotKeys = isMac ? FILE_SHORTCUTS.Mac : FILE_SHORTCUTS.Windows
const labelHotKeys = isMac ? LABEL_SHORTCUTS.Mac : LABEL_SHORTCUTS.Windows

const HOT_KEYS = {
  ...commonHotKeys,
  ...fileHotKeys,
  ...labelHotKeys,
}

export default HOT_KEYS
