import { createAsyncThunk } from '@reduxjs/toolkit'
import { findIndex, isEmpty } from 'lodash'

import { LabelingState, ThunkConfig } from '@/stores/slices/Labeling/types'
import { API } from '@/api'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'
import { LabelingNodeItem } from '@/api/LabelingNode/types'

const loadAllLabelingItems = async (labelingNodeId: string, length: number) => {
  const MAX_FILES_PER_PAGE = 50
  const itemsPerPage = Math.min(length, MAX_FILES_PER_PAGE)
  const MAX_PAGE = Math.ceil(length / itemsPerPage)

  const result = await Promise.all(
    Array.from({ length: MAX_PAGE }, (_, page) => API().Labeling.getFiles(labelingNodeId, {
      page,
      itemsPerPage,
    }))
  )

  return result.flatMap((f) => f.list)
}

export const loadLabelingItems = createAsyncThunk<any, string, ThunkConfig>(
  'labeling/load-labeling-items',
  async (labelingNodeId, thunkAPI) => {
    // const prevFiles = thunkAPI.getState().labeling.files
    // const labelingItems = await loadAllLabelingItems(labelingNodeId, prevFiles.length)
    const filesLength = thunkAPI.getState().labeling.numOfLabelingData
    const labelingItems = await loadAllLabelingItems(labelingNodeId, filesLength)

    // return prevFiles.map((f, i) => (labelingItems[i] || f))
    return labelingItems
  }
)

export const filterLabelingItems = createAsyncThunk<{ itemList: LabelingNodeItem[], filters: LabelingState['fileFilter'] }, LabelingState['fileFilter'], ThunkConfig>(
  'labeling/filter-labeling-items',
  async (filters, thunkAPI) => {
    const { initialNodeStatus, labelingNodeId, originalFiles } = thunkAPI.getState().labeling
    const isReviewNode = initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS
    const MAX_FILES_PER_PAGE = 20

    const itemList = []
    let page = 0
    while (page >= 0) {
      // eslint-disable-next-line no-await-in-loop
      const res = await API().Labeling.getFiles(labelingNodeId, {
        page,
        itemsPerPage: MAX_FILES_PER_PAGE,
        labelingFilter: (!isReviewNode && !isEmpty(filters)) ? filters.join(',') : undefined,
        reviewFilter: (isReviewNode && !isEmpty(filters)) ? filters.join(',') : undefined,
      })
      itemList.push(...res.list)
      if (MAX_FILES_PER_PAGE * (page + 1) >= res.paging.total_count) {
        page = -1
      } else {
        page += 1
      }
    }
    if (itemList.length > 0) {
      await API().Labeling.updateUserMeta({ labelingNodeId, lastIndex: findIndex(originalFiles, { _id: itemList[0]._id }), lastLabelingItemId: itemList[0]._id, duration: 0 })
    }

    return { itemList, filters }
  }
)

/**
 * 이미지를 모두 불러온 후,
 * Annotation(이미지를 넘길 때마다 history 초기화)과
 * Classification(모든 이미지의 history를 초기화)에서의 동작이 달라 별도의 action으로 분리
 *  */
export const loadClassificationItems = createAsyncThunk<any, string, ThunkConfig>(
  'labeling/load-classification-items',
  async (labelingNodeId, thunkAPI) => {
    const prevFiles = thunkAPI.getState().labeling.files
    const labelingItems = await loadAllLabelingItems(labelingNodeId, prevFiles.length)

    return prevFiles.map((f, i) => (f || labelingItems[i]))
  }
)
