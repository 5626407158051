export const TOAST_STATES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  DEFAULT: 'default',
}
export type ToastValueType = (typeof TOAST_STATES)[keyof typeof TOAST_STATES]

export type ToastType = {
  id: number | string,
  status: ToastValueType,
  autoHide?: boolean,
  title?: string,
  message?: string,
  /* customButton의 onClick이 없으면, 토스트닫기 (default) */
  customButtonList?: { title: string, onClick?: () => void }[]
  showCloseButton?: boolean
}

export type ToastData = Omit<ToastType, 'id'>
export type NotiCombinedData = ToastData

export type NotificationContext = {
  notifications: ToastType[],
  addNotification: (data: NotiCombinedData) => void,
  removeNotification: (id: number | string) => void,
}
