import { queryClient } from '@/api/react-query/queryClient'
import { labelingNodeKeys } from '@/api/react-query/queryKeys/labelingNode'
import { API } from '@/api'
import { datasetNodeKeys } from '@/api/react-query/queryKeys/datasetNode'

const INIT_ITEMS_PER_PAGE = 5

export const fetchQueryData = {
  overview: (labelingNodeId: string) => queryClient.fetchQuery({
    queryKey: labelingNodeKeys.detail(labelingNodeId),
    queryFn: () => API().Labeling.getNodeDetails(labelingNodeId),
  }),
  initFiles: (labelingNodeId: string, startIndex: number) => {
    const end = startIndex + (INIT_ITEMS_PER_PAGE - 1)
    const currentPage = Math.floor(startIndex / INIT_ITEMS_PER_PAGE)

    return queryClient.fetchQuery({
      queryKey: labelingNodeKeys.fileSegment(labelingNodeId, startIndex, end),
      queryFn: () => API().Labeling.getFiles(labelingNodeId, {
        page: currentPage,
        itemsPerPage: INIT_ITEMS_PER_PAGE,
      }),
      staleTime: 1 * 60 * 60 * 1000,
      gcTime: 3 * 60 * 60 * 1000,
    })
  },
  objects: (labelingNodeId: string, itemId: string) => queryClient.fetchQuery({
    queryKey: labelingNodeKeys.objects(labelingNodeId, itemId),
    queryFn: () => API().Labeling.getObjectsOnFile(itemId),
  }),
  dataset: (projectId: string, datasetNodeId: string) => queryClient.fetchQuery({
    queryKey: datasetNodeKeys.detail(projectId, datasetNodeId),
    queryFn: () => API().DatasetNode.getDatasetNodeDetail(datasetNodeId),
  }),
  classes: (labelingNodeId: string) => queryClient.fetchQuery({
    queryKey: labelingNodeKeys.classes(labelingNodeId),
    queryFn: () => API().Labeling.getUserClasses(labelingNodeId),
  }),
  item: (labelingNodeId: string, labelingItemId: string) => queryClient.fetchQuery({
    queryKey: labelingNodeKeys.file(labelingNodeId, labelingItemId),
    queryFn: () => API().Labeling.getFileDetails(labelingItemId),
  }),
}

export const updateQueryData = {
  class: () => {},
}

export const invalidateQueryData = {
  all: () => queryClient.invalidateQueries({
    queryKey: labelingNodeKeys.all,
    refetchType: 'all',
  }),
  overview: (labelingNodeId: string) => queryClient.invalidateQueries({
    queryKey: labelingNodeKeys.detail(labelingNodeId),
    refetchType: 'all',
  }),
  objects: (labelingNodeId: string, itemId: string) => queryClient.invalidateQueries({
    queryKey: labelingNodeKeys.objects(labelingNodeId, itemId),
    refetchType: 'all',
  }),
}
