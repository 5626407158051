import BaseApi from '@/api/Base/BaseApi'
import {
  CreatePreprocessingResultNodePayload,
  PreprocessingResultNodeInfo,
  UpdatePreprocessingResultNodePayload
} from '@/api/PreprocessingResultNode/types'

const ROOT = '/preprocessing-result'

export default class PreprocessingResultNodeApi extends BaseApi {
  createPreprocessingResultNode(payload: CreatePreprocessingResultNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post(`/project/${projectId}${ROOT}/create`, rest)
  }

  getPreprocessingResultNodeDetail(preprocessingResultNodeId: string) {
    return this.agent.get<PreprocessingResultNodeInfo>(`${ROOT}/${preprocessingResultNodeId}`)
  }

  updatePreprocessingResultNode(payload: UpdatePreprocessingResultNodePayload) {
    const { preprocessingResultNodeId, ...rest } = payload
    return this.agent.put<PreprocessingResultNodeInfo>(`${ROOT}/${preprocessingResultNodeId}`, rest)
  }

  deletePreprocessingResultNode(preprocessingResultNodeId: string) {
    return this.agent.delete(`${ROOT}/${preprocessingResultNodeId}`)
  }
}
