import { ListenerMiddlewareInstance, createListenerMiddleware } from '@reduxjs/toolkit'
import { commentAddListener, commentDeleteListener, commentLoadListener, commentPositionListener, commentResolveListener } from '@/stores/slices/Labeling/listeners/comment'
import { AppDispatch, RootState } from '@/stores/types'
import { fileStatusListener, filterListener } from '@/stores/slices/Labeling/listeners/file'

export const listenerMiddleware = createListenerMiddleware()

const instance = listenerMiddleware as ListenerMiddlewareInstance<RootState, AppDispatch>

/** Labeling */
fileStatusListener(instance)
filterListener(instance)

/** Labeling - Review */
commentLoadListener(instance)
commentAddListener(instance)
commentDeleteListener(instance)
// commentAnchorListener(instance)
commentResolveListener(instance)
commentPositionListener(instance)
