export const LABEL_FILE_STATUS = {
  /** labeler */
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  // IN_PROGRESS_HOLD: 'IN_PROGRESS_HOLD',
  FINISHED: 'FINISHED',
  REQUEST_REVIEW: 'REQUEST_REVIEW',

  /** reviewer */
  REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
  // REVIEW_HOLD: 'REVIEW_HOLD',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
} as const

export const LABELING_NODE_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
  COMPLETED: 'COMPLETED',
} as const

/** @deprecated */
export const LABEL_OK_STATUS = {
  waiting: 'WAITING',
  ok: 'OK',
  ng: 'NG',
} as const

export const LABEL_ONGOING = [LABEL_FILE_STATUS.IN_PROGRESS, LABEL_FILE_STATUS.NOT_STARTED, LABEL_FILE_STATUS.REJECTED]
export const LABEL_FINISHED = [LABEL_FILE_STATUS.FINISHED, LABEL_FILE_STATUS.REQUEST_REVIEW, LABEL_FILE_STATUS.COMPLETED]
export const REVIEW_ONGOING = [LABEL_FILE_STATUS.REVIEW_IN_PROGRESS]
export const REVIEW_FINISHED = [LABEL_FILE_STATUS.REJECTED, LABEL_FILE_STATUS.COMPLETED]
