import { ValueOf } from '@ahha/utils/@types/typeUtils'

export const ModelTypes = {
  ANOMALY_DETECTION: 'ANOMALY_DETECTION',
  SEGMENTATION: 'SEGMENTATION',
  CLASSIFICATION: 'CLASSIFICATION',
  OBJECT_DETECTION: 'OBJECT_DETECTION',
  // VIDEO: 5,
  // TABULAR: 6,
} as const

export const TrainingModelTypes = {
  ANOMALY_MEDIUM: 'MEDIUM',
  ANOMALY_SMALL: 'SMALL',
  SEGMENTATION: 'SEG',
  CLASSIFICATION: 'CLS',
  OBJECT_DETECTION: 'OBJ',
}
export type ModelValueType = ValueOf<typeof ModelTypes>
export type TrainingModelValueType = ValueOf<typeof TrainingModelTypes>
export const MODEL_LABELS: Record<string, string> = {
  ANOMALY_DETECTION: 'common_anomalyDetection',
  SEGMENTATION: 'common_segmentation',
  CLASSIFICATION: 'common_classification',
  OBJECT_DETECTION: 'common_objectDetection',
}

export const EvaluationTypes = {
  TRAIN: 'TRAIN',
  VALIDATION: 'VALIDATION',
  INFERENCE: 'INFERENCE',
} as const
export type EvaluationValueType = ValueOf<typeof EvaluationTypes>

export const ModelColors = {
  ANOMALY_DETECTION: '#1FB356',
  SEGMENTATION: '#F97316',
  CLASSIFICATION: '#F2BC1A',
  OBJECT_DETECTION: '#1A75FF',
  OCR: '#898989',
}

export const TaskStatusTypes = {
  CREATED: 'CREATED',
  PREPARING: 'PREPARING',
  WAITING: 'WAITING',
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  PAUSED: 'PAUSED',
  RESUMED: 'RESUMED',
  CANCELED: 'CANCELED',
  TERMINATED: 'TERMINATED',
  ERROR: 'ERROR',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN: 'UNKNOWN',
  STOPPING: 'STOPPING',
} as const
export type TaskStatusValueType = ValueOf<typeof TaskStatusTypes>

export const ERROR_TASKS: TaskStatusValueType[] = [
  TaskStatusTypes.ERROR,
  TaskStatusTypes.TIMEOUT,
  TaskStatusTypes.UNKNOWN,
]
export const RUNNING_TASKS: TaskStatusValueType[] = [
  TaskStatusTypes.CREATED,
  TaskStatusTypes.PREPARING,
  TaskStatusTypes.WAITING,
  TaskStatusTypes.RUNNING,
]
