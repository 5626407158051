import BaseApi from '@/api/Base/BaseApi'
import {
  CreateInferenceNodePayload,
  UpdateInferenceNodePayload,
  InferenceNodeInfo,
  GetInferenceStatusResponse,
  GetInferenceResultResponse
} from '@/api/InferenceNode/types'
import { CommonListPayload, DatasetNodeItemListResponse } from '@/api/DatasetNode/types'
import { GetLabelingNodeItemsResponse } from '@/api/LabelingNode/types'

const ROOT = '/inference'

const defaultPaginationOptions = {
  itemsPerPage: 40,
  page: 0,
  sortDir: 'desc',
  sortKey: 'createdAt',
}

export default class InferenceNodeApi extends BaseApi {
  createInferenceNode(payload: CreateInferenceNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<InferenceNodeInfo>(`/project/${projectId}/inference/create`, rest)
  }

  getInferenceNodeDetail(inferenceNodeId: string) {
    return this.agent.get<InferenceNodeInfo>(`${ROOT}/${inferenceNodeId}`)
  }

  updateInferenceNode(payload: UpdateInferenceNodePayload) {
    const { inferenceNodeId, ...rest } = payload
    return this.agent.put<InferenceNodeInfo>(`${ROOT}/${inferenceNodeId}`, rest)
  }

  deleteInferenceNode(inferenceNodeId: string) {
    return this.agent.delete(`${ROOT}/${inferenceNodeId}`)
  }

  startInference(inferenceNodeId: string) {
    return this.agent.post<GetInferenceStatusResponse>(`${ROOT}/${inferenceNodeId}/inference`)
  }

  stopInference(inferenceNodeId: string) {
    return this.agent.put<GetInferenceStatusResponse>(`${ROOT}/${inferenceNodeId}/inference/stop`)
  }

  getInferenceStatus(inferenceNodeId: string) {
    return this.agent.get<GetInferenceStatusResponse>(`${ROOT}/${inferenceNodeId}/inference/status`)
  }

  getInferenceResult(inferenceNodeId: string) {
    return this.agent.get<GetInferenceResultResponse>(`${ROOT}/${inferenceNodeId}/inference`)
  }

  getInferenceItems(inferenceNodeId: string, options?: CommonListPayload) {
    const { itemsPerPage, page, sortDir, sortKey } = options ?? defaultPaginationOptions
    return this.agent.get<DatasetNodeItemListResponse>(`${ROOT}/${inferenceNodeId}/items`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }
}
