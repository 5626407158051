/*eslint-disable */

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { ThemeProvider } from 'styled-components'
import { variables } from '@/assets/styles/variables'
import { mixin } from '@/assets/styles/mixin'

export type ThemeConfig = typeof DefaultCampTheme.theme

export type CampTheme = {
  themeId: string,
  themeName: string,
  theme: ThemeConfig,
}

export const DefaultCampTheme = {
  themeId: 'default',
  themeName: 'Default Theme',
  theme: {
    bg: {
      primary: '#0A0A0A',
      primarySub: '#898989',
      primaryTertiary: '#3D3D3D',
      primaryHover: '#242424',
      secondary: '#F97316',
      secondaryHover: '#E36914',
      secondarySub: '#FEF1E8',
      select: '#EAF2FE',
      hover: '#F7F7F8',
      disabled: '#F3F3F3',
      error: '#E51919',
      errorDisabled: '#F4BCBC',
      errorSub: '#FDF2F2',
      info: '#0066FF',
      infoSub: '#EAF2FE',
      inverse: '#FFFFFF',
      warning: '#F2BC1A',
      warningSub: '#FFFAEB',
      success: '#1FB356',
      successSub: '#F3FCF6',
    },
    text: {
      primary: '#0A0A0A',
      secondary: '#3D3D3D',
      tertiary: '#575757',
      disabled: '#BDBDBD',
      inverse: '#FFFFFF',
      secondaryOrange: '#B15210',
      error: '#C40000',
      info: '#0054D1',
      success: '#136C34',
      warning: '#8F6B00',
    },
    border: {
      border1: '#0A0A0A',
      border2: '#3D3D3D',
      border3: '#898989',
      border4: '#DFDFE0',
      border5: '#F3F3F3',
      borderWhite: '#FFFFFF',
      secondary: '#F97316',
      disabled: '#F0F0F0',
      error: '#E51919',
      select: '#0066FF',
      success: '#22C55E',
      warning: '#F2BC1A',
    },
    icon: {
      primary: '#0A0A0A',
      secondary: '#575757',
      tertiary: '#898989',
      secondaryOrange: '#F97316',
      inverse: '#FFFFFF',
      disabled: '#DFDFE0',
      error: '#E51919',
      info: '#0066FF',
      warning: '#F2BC1A',
      success: '#1FB356',
    },
    page: {
      primary: '#FFFFFF',
      secondary: '#F7F7F8',
      tertiary: '#F3F3F3',
      inverse: '#000000',
    },
    nodes: {
      dataset: {
        border: '#0066FF',
        background: '#EAF2FE',
      },
      labeling: {
        border: '#F97316',
        background: '#FFF9F5',
      },
      review: {
        border: '#0805A1',
        background: '#0805A11A',
      },
      train: {
        border: '#6200EE',
        background: '#EFE6FD',
      },
      validation: {
        border: '#F2BC1A',
        background: '#FFFAEB',
      },
      inference: {
        border: '#08B547',
        background: '#08B5471A',
        icon: '#00A6A6',
      },
      imageMatching: {
        border: '#626262',
        background: '#6262621A',
        icon: '#0A0A0A',
      },
      preprocessing: {
        border: '#C222C5',
        background: '#C222C51A',
      },
      preprocessingResult: {
        border: '#626262',
        background: '#6262621A',
        icon: '#0A0A0A',
      },
    },
    dim: '#000000B3',
  },
}

export type CampThemeContextValue = {
  themeId: string,
  availableThemes: CampTheme[],
  currentTheme: ThemeConfig,
  changeTheme: (themeId: string) => void,
}


const CampThemeContext = createContext<CampThemeContextValue>({
  themeId: 'default',
  availableThemes: [DefaultCampTheme],
  currentTheme: DefaultCampTheme.theme,
  changeTheme: (themeId: string) => {
  },
})

export const CampThemeProvider = ({ children, availableThemes, themeId, setThemeId }
                                    : {
  children: React.ReactNode,
  availableThemes?: CampTheme[],
  themeId?: string,
  setThemeId?: (theme_id: string) => void
}) => {
  const [_themeId, _setThemeId] = useState(themeId || 'default')
  const [_currentTheme, _setCurrentTheme] = useState<ThemeConfig>(DefaultCampTheme.theme)
  const [_availableThemes, _setAvailableThemes] = useState([DefaultCampTheme])

  useEffect(() => {
    // default theme는 availableThemes에 선언되어 있지 않더라도 추가.
    const newThemes = [DefaultCampTheme]
    if (Array.isArray(availableThemes) && availableThemes.length > 0) {
      availableThemes.forEach((v) => {
        // 중복된 themeId가 있을 경우, 덮어쓰기 처리.
        const foundIdx = newThemes.findIndex((t) => (t.themeId === v.themeId))
        if (foundIdx >= 0) {
          newThemes.splice(foundIdx, 1)
        }
        newThemes.push(v)
      })
    }
    _setAvailableThemes(newThemes)
  }, [availableThemes])

  const isAvailableThemeId = (id: string, themes?: CampTheme[]) => {
    const found = themes?.find((v) => v.themeId === id)
    if (found) {
      return true
    }
    return (id === 'default')
  }

  useEffect(() => {
    if (isEmpty(themeId)) {
      changeTheme('default')
    } else if (isAvailableThemeId(themeId!, availableThemes)) {
      _setThemeId(themeId!)
    } else {
      changeTheme('default')
    }
  }, [availableThemes, themeId])

  // eslint-disable-next-line camelcase
  const changeTheme = (theme_id: string) => {
    if (setThemeId) {
      setThemeId(theme_id)
    } else {
      _setThemeId(theme_id)
    }
  }

  useEffect(() => {
    const foundTheme = _availableThemes?.find((v) => v.themeId === _themeId)
    if (foundTheme) {
      _setCurrentTheme(foundTheme.theme)
    }
  }, [_availableThemes, _themeId])

  const value = useMemo(() => {
    return {
      themeId: _themeId,
      availableThemes: _availableThemes,
      currentTheme: _currentTheme,
      changeTheme,
    }
  }, [_themeId, _currentTheme, _availableThemes])
  return (
    <CampThemeContext.Provider value={value}>
      <ThemeProvider theme={{ colors: _currentTheme, variables, mixin }}>
        {children}
      </ThemeProvider>
    </CampThemeContext.Provider>
  )
}

export const useCampTheme = () => {
  const contextValue = useContext(CampThemeContext)
  if (contextValue === undefined) {
    console.error('Error fail to get context value')
  }
  return contextValue
}
