import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import loadable from '@loadable/component'

import { SiteUrls } from '@/routes/siteUrls'

const MainLayout = loadable(() => import('@/layouts/MainLayout'))
const NotSignedInLayout = loadable(() => import('@/layouts/NotSignedInLayout'))
const LandingPage = loadable(() => import('@/pages/landing/LandingPage'))

// Login
const LoginLayout = loadable(() => import('@/pages/Login/LoginLayout'))
const LoginMain = loadable(() => import('@/pages/Login/Login'))
const SignUp = loadable(() => import('@/pages/Login/SignUp'))
const RecoverPassword = loadable(() => import('@/pages/Login/RecoverPassword'))
const ExpiredUrl = loadable(() => import('@/pages/Login/ExpiredUrl'))

// Workspace
const NoWorkspace = loadable(() => import('@/pages/Workspace/NoWorkspace'))
const JoinWorkspace = loadable(() => import('@/pages/Workspace/Join'))
const CreateWorkspace = loadable(() => import('@/pages/Workspace/Create'))
const WorkspaceMain = loadable(() => import('@/pages/Workspace/Main'))
const WorkspaceSettings = loadable(() => import('@/pages/Workspace/Settings'))
const WorkspaceNotificationSettings = loadable(() => import('@/pages/Workspace/NotificationSettings'))
const WorkspaceMembers = loadable(() => import('@/pages/Workspace/Members'))
const WorkspacePricing = loadable(() => import('@/pages/Workspace/Pricing'))

// Project
const Projects = loadable(() => import('@/pages/Project/Projects'))

// dashboard
// const DashboardEdit = loadable(() => import('@/pages/DataCamp/Dashboard/Edit'))
// const DashboardMain = loadable(() => import('@/pages/DataCamp/Dashboard/Main'))
// const WidgetConfig = loadable(() => import('@/pages/DataCamp/Dashboard/WidgetConfig/WidgetConfig'))

// DataStorage
const Storage = loadable(() => import('@/pages/Storage/Storage'))

// Database
// const DatabaseList = loadable(() => import('@/pages/DataCamp/Database/List'))
// const CollectionList = loadable(() => import('@/pages/DataCamp/Database/Collections/List'))
// const CollectionCreate = loadable(() => import('@/pages/DataCamp/Database/Collections/Create'))
// const CollectionDetail = loadable(() => import('@/pages/DataCamp/Database/Collections/Detail'))

const RenewedPipeline = loadable(() => import('@/pages/RenewedPipeline/RenewedPipeline'))

const LabelingAnom = loadable(() => import('@/pages/Labeling/AnomalyDetection/Detail'))
const LabelingCls = loadable(() => import('@/pages/Labeling/Classification/Detail'))
const LabelingObjDet = loadable(() => import('@/pages/Labeling/ObjectDetection/Detail'))

const ModelResult = loadable(() => import('@/pages/Result/ModelResult'))
const ValidationResult = loadable(() => import('@/pages/Result/ValidationResult'))
const InferenceResult = loadable(() => import('@/pages/Result/InferenceResult'))

// user settings
const UserSettings = loadable(() => import('@/pages/User/UserSettings'))

// new project
const ProjectMain = loadable(() => import('@/pages/Project/Main'))
const ProjectSettings = loadable(() => import('@/pages/Project/ProjectSettings'))
const ProjectNotificationSettings = loadable(() => import('@/pages/Project/NotificationSettings'))

const routes = (isSignIn?: boolean): RouteObject[] => [
  {
    path: SiteUrls.Landing,
    // element: isSignIn ? <Navigate to={SiteUrls.Main._} replace /> : <NotSignedInLayout />,
    element: <NotSignedInLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={SiteUrls.Login._} replace />,
      },
      {
        path: SiteUrls.Login._,
        element: <LoginLayout />,
        children: [
          {
            path: SiteUrls.Login._,
            element: <LoginMain />,
          },
          {
            path: SiteUrls.Login.SignUp,
            element: <SignUp />,
          },
          {
            path: SiteUrls.Login.RecoverPassword,
            element: <RecoverPassword />,
          },
          {
            path: SiteUrls.Login.ExpiredInvitation,
            element: <ExpiredUrl />,
          },
        ],
      },
    ],
  },
  {
    path: SiteUrls.Main._,
    // element: isSignIn ? <MainLayout /> : <Navigate to={SiteUrls.Login._} replace />,
    element: <MainLayout />,
    children: [
      {
        path: SiteUrls.Main.Workspace.Join,
        element: <JoinWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.Create,
        element: <CreateWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.NoWorkspace,
        element: <NoWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.Main,
        element: <WorkspaceMain />,
        children: [
          {
            path: SiteUrls.Main.Workspace.Settings,
            element: <WorkspaceSettings />,
          },
          {
            path: SiteUrls.Main.Workspace.NotificationSettings,
            element: <WorkspaceNotificationSettings />,
          },
          {
            path: SiteUrls.Main.Workspace.Projects,
            element: <Projects />,
          },
          {
            path: SiteUrls.Main.Workspace.Members,
            element: <WorkspaceMembers />,
          },
          {
            path: SiteUrls.Main.Workspace.Pricing,
            element: <WorkspacePricing />,
          },
          {
            path: SiteUrls.Main.Workspace.Storage._,
            element: <Storage />,
          },
          {
            path: SiteUrls.Main.Workspace.Storage.folder(':id'),
            element: <Storage />,
          },
        ],
      },
      {
        path: SiteUrls.Main.Workspace.Invited(':invitationCode'),
        element: <WorkspaceMain />,
      },
      {
        path: SiteUrls.Main.Project.Main(':projectId'),
        element: <ProjectMain />,
        children: [
          {
            path: SiteUrls.Main.Project.Settings(':projectId'),
            element: <ProjectSettings />,
          },
          {
            path: SiteUrls.Main.Project.NotificationSettings(':projectId'),
            element: <ProjectNotificationSettings />,
          },
          {
            path: SiteUrls.Main.Project.ModelManagement(':projectId'),
            element: <p>모델 관리</p>,
          },
          {
            path: SiteUrls.Main.Project.Dashboard(':projectId'),
            element: <p>대시보드</p>,
          },
          {
            path: SiteUrls.Main.Project.Pipeline(':projectId'),
            element: <RenewedPipeline />,
          },
        ],
      },
      {
        path: '',
        element: <Projects />,
      },
      // {
      //   path: SiteUrls.Main.Dashboard._,
      //   element: <DashboardMain />,
      // },
      // {
      //   path: SiteUrls.Main.Dashboard.Edit,
      //   element: <DashboardEdit />,
      // },
      // {
      //   path: SiteUrls.Main.Dashboard.WidgetConfig,
      //   element: <WidgetConfig />,
      // },
      {
        path: SiteUrls.Main.Labeling.Anomaly(':id'),
        element: <LabelingAnom />,
      },
      {
        path: SiteUrls.Main.Labeling.Classification(':id'),
        element: <LabelingCls />,
      },
      {
        path: SiteUrls.Main.Labeling.ObjDet(':id'),
        element: <LabelingObjDet />,
      },
      {
        path: SiteUrls.Main.Result.Model(':projectId', ':nodeId'),
        element: <ModelResult />,
      },
      {
        path: SiteUrls.Main.Result.Validation(':projectId', ':nodeId'),
        element: <ValidationResult />,
      },
      {
        path: SiteUrls.Main.Result.Inference(':projectId', ':nodeId'),
        element: <InferenceResult />,
      },
      {
        path: SiteUrls.Main.User.Settings,
        element: <UserSettings />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={SiteUrls.Main.Workspace.NoWorkspace} replace />,
  },
]

export default routes
